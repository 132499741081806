import { createGlobalState } from '@vueuse/core'
import { readonly, ref } from 'vue'
import { FrontEndConfig } from '@/app/services/FrontEndConfigTypes'

export const useConfig = createGlobalState(() => {
  const _initialized = ref(false)
  const _config = ref<FrontEndConfig>({} as FrontEndConfig)

  const init = (config?: FrontEndConfig) => {
    if (!config) {
      throw new Error('configuration not missing')
    }
    _config.value = config
    // @ts-ignore
    if (APP_ENV && APP_ENV === 'local') {
      // @ts-ignore
      _config.value.flare.isEnabled = FLARE_IS_ENABLED === 'true'
      // @ts-ignore
      _config.value.flare.projectKey = FLARE_KEY

      // @ts-ignore
      _config.value.posthog.isEnabled = PH_IS_ENABLED === 'true'
      // @ts-ignore
      _config.value.posthog.projectApiKey = PH_PROJECT_API_KEY
      // @ts-ignore
      _config.value.posthog.instanceAddress = PH_INSTANCE_ADDRESS
      // @ts-ignore
      _config.value.features.appIsLabeledAsBeta = F_IS_LABELED_AS_BETA === 'true'

      // @ts-ignore
      _config.value.builderIO.isEnabled = BUILDERIO_IS_ENABLED === 'true'
      // @ts-ignore
      _config.value.builderIO.publicApiKey = BUILDERIO_PUBLIC_API_KEY

      // @ts-ignore
      _config.value.authentication.google.clientId = AUTHENTICATION_GOOGLE_CLIENT_ID
      // @ts-ignore
      _config.value.authentication.google.redirect = AUTHENTICATION_GOOGLE_REDIRECT
      // @ts-ignore
      _config.value.authentication.x.clientId = AUTHENTICATION_X_CLIENT_ID
      // @ts-ignore
      _config.value.authentication.x.redirect = AUTHENTICATION_X_REDIRECT
      // @ts-ignore
      _config.value.authentication.linkedIn.clientId = AUTHENTICATION_LINKEDIN_CLIENT_ID
      // @ts-ignore
      _config.value.authentication.linkedIn.redirect = AUTHENTICATION_LINKEDIN_REDIRECT

      // @ts-ignore
      _config.value.gtm.isActivated = GTM_IS_ACTIVATED === 'true'
      // @ts-ignore
      _config.value.gtm.isEnabled = GTM_IS_ENABLED === 'true'
      // @ts-ignore
      _config.value.gtm.debug = GTM_DEBUG === 'true'
      // @ts-ignore
      _config.value.gtm.id = GTM_ID
      // @ts-ignore
      _config.value.gtm.auth = GTM_AUTH
      // @ts-ignore
      _config.value.gtm.preview = GTM_PREVIEW
      // @ts-ignore
      _config.value.gtm.cookiesWin = GTM_COOKIES_WIN
    }

    _initialized.value = true
  }

  const fake = () => {
    const fakeConfig: FrontEndConfig = {
      environment: 'testing',
      fathom: {
        isEnabled: false,
        siteId: 'null',
        url: 'null',
      },

      posthog: {
        isEnabled: false,
        projectApiKey: 'null',
        instanceAddress: 'null',
      },

      yBug: {
        isEnabled: false,
        key: 'null',
      },

      version: {
        commit: '',
        mergeRequestId: '',
        deployedAt: '',
      },

      flare: {
        isEnabled: false,
        projectKey: '',
      },
      features: {
        appIsLabeledAsBeta: false,
      },
      builderIO: {
        isEnabled: false,
        publicApiKey: '',
      },
      authentication: {
        google: {
          clientId: null,
          redirect: null,
        },
        x: {
          clientId: null,
          redirect: null,
        },
        linkedIn: {
          clientId: null,
          redirect: null,
        },
      },
      gtm: {
        isActivated: false,
        isEnabled: false,
        debug: false,
        id: '',
        auth: '',
        preview: '',
        cookiesWin: '',
      },
    }
    init(fakeConfig)
  }

  return {
    get: () => {
      if (!_initialized.value) {
        throw new Error('Configuration is not initialized')
      }
      return readonly(_config.value)
    },
    init,
    fake,
  }
})
