import { ref, type Ref } from 'vue'
import type { RouteLocation } from 'vue-router'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import type { TRouteResolver } from '@/domain/Authentication/contracts/TRouteResolver'

export const resolveAfterSignInRedirectTarget = (
  routeResolver: TRouteResolver,
  signInContext: TSignInContext,
  asHref = false,
): string | (RouteLocation & { href: string }) => {
  const target: Ref<undefined | (RouteLocation & { href: string })> = ref<
    | undefined
    | (RouteLocation & {
        href: string
      })
  >(undefined)

  if (signInContext.prompt) {
    target.value = routeResolver({
      name: CRouteNames.documents.create,
      query: {
        loading_style: 'skeleton',
        ...signInContext,
      },
    })
  }

  if (signInContext.share_id) {
    const query = { ...signInContext }
    delete query.share_id

    target.value = routeResolver({
      name: CRouteNames.shareTopic,
      params: {
        shareId: signInContext.share_id,
      },
      query,
    })
  }

  if (!target.value) {
    target.value = routeResolver({
      name: CRouteNames.dashboard,
    })
  }

  return asHref ? target.value.href : target.value
}
