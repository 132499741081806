<template>
  <div
    class="flex justify-between items-center w-full px-4 min-h-nav max-h-nav border-b border-b-base-150 shadow-lg"
    :class="componentName"
  >
    <div class="w-full flex h-[36px] flex-row items-center">
      <slot name="left" />
      <slot name="center" />
      <slot name="right" />
    </div>
    <slot name="bottom" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export const componentName = 'BaseNavigationLayout'

export default defineComponent({
  name: componentName,
  setup: () => ({ componentName }),
})
</script>

<style scoped></style>
