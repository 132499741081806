<template>
  <SectionLayout
    v-show="props.faqs.length > 0"
    width="narrow"
    :visualize-width="false"
    tag="section"
    class="mt-4 lg:mt-8 mb-6 lg:mb-10"
    :class="[props.containerClasses, componentName]"
    :content-container-classes="'items-center'"
  >
    <div
      v-if="!isEmpty(props.title)"
      class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12"
    >
      <h2
        class="mb-4 text-3xl tracking-tight font-extrabold text-base-content"
        v-html="props.title"
      />
      <div v-if="props.sublines.length > 0" class="flex flex-col space-y-2">
        <p
          v-for="(sub, index) in props.sublines"
          :key="index"
          class="font-light text-base-500 sm:text-xl"
          v-html="sub.text"
        />
      </div>
    </div>
    <dl
      class="space-y-6 divide-base-300 divide-y border-y w-full max-w-screen-xs md:max-w-screen-sm xl:max-w-screen-md"
    >
      <Disclosure
        v-for="faq in props.faqs"
        :key="faq.question"
        v-slot="{ open }"
        as="div"
        class="pt-6 last:pb-6"
      >
        <dt>
          <DisclosureButton
            class="flex w-full items-start justify-between text-left text-base-content"
          >
            <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
            <span class="ml-6 flex h-7 items-center">
              <PlusSmIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
              <MinusSmIcon v-else class="h-6 w-6" aria-hidden="true" />
            </span>
          </DisclosureButton>
        </dt>
        <DisclosurePanel as="dd" class="mt-2 pr-12">
          <p class="text-base text-left leading-7 text-base-500">{{ faq.answer }}</p>
        </DisclosurePanel>
      </Disclosure>
    </dl>
  </SectionLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'FaqSection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/vue/outline/'
import { isEmpty } from 'lodash-es'

export type TFaqSection = {
  title?: string
  sublines?: { text: string }[]
  containerClasses?: string
  faqs?: {
    question: string
    answer: string
  }[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = withDefaults(defineProps<TFaqSection & { attributes?: any }>(), {
  title: undefined,
  sublines: () => [],
  containerClasses: undefined,
  faqs: () => [],
})
</script>

<style scoped></style>
