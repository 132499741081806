<template>
  <BaseDropDown>
    <template #menuButton="{ toggleMenu }">
      <button id="menu" aria-haspopup="true" aria-controls="menu" @click="toggleMenu">
        <span class="sr-only">Open options</span>
        <UserAvatar :name="userName" />
      </button>
    </template>
    <template #items="{ closeMenu, openMenu, toggleMenu }">
      <slot
        name="prependItems"
        :toggleMenu="toggleMenu"
        :closeMenu="closeMenu"
        :openMenu="openMenu"
      />
      <div class="mt-3 flex cursor-text items-center gap-2 px-3" :class="componentName">
        <div
          v-if="showAvatar"
          class="flex h-8 w-8 items-center justify-center rounded-full border border-state-error bg-state-error-content text-sm font-semibold text-state-error"
        >
          <h5>{{ userNameInitials }}</h5>
        </div>
        <div class="flex flex-grow items-center justify-between gap-2">
          <InlineInput
            class="max-w-[100px] cursor-pointer text-sm font-normal leading-6 text-base-700"
            :value="userName"
            :multiline="false"
            :edit-by-inline-click="false"
            input-aria-label="Username"
            readonly-aria-label="Username"
            :action-aria-labels="['Edit username', 'Save username']"
            @inline-input-changed="userNameChanged"
          />
        </div>
      </div>
      <slot name="middle" />
      <slot
        name="appendItems"
        :toggleMenu="toggleMenu"
        :closeMenu="closeMenu"
        :openMenu="openMenu"
      >
        <div class="mt-2 hover:bg-base-200">
          <button
            id="logout"
            aria-label="Log current user out"
            class="block w-full px-6 py-4 text-left text-sm font-normal leading-none text-base-content hover:underline"
            @click="logOutAction(closeMenu)"
          >
            Logout
          </button>
        </div>
      </slot>
    </template>
  </BaseDropDown>
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue'
import BaseDropDown from '@/app/components/BaseDropDown.vue'
import UserAvatar from './UserAvatar.vue'
import InlineInput from '@/domain/cards/components/InlineInput.vue'
import { useUserStore } from '@/app/services/useUserStore'
import { useRouter } from 'vue-router'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'

export const componentName = 'ProfileMenu'
export default defineComponent({
  name: componentName,
  components: {
    BaseDropDown,
    UserAvatar,
    InlineInput,
  },
  props: {
    showAvatar: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUserStore()
    const { userName, userNameInitials } = userStore
    const { replace } = useRouter()

    onBeforeMount(() => {
      userStore.refresh()
    })

    const userNameChanged = ({ event }) => {
      userStore.updateUserName(event.target.value)
    }

    const logOutAction = async (closeAction) => {
      await useAuthentication().logout({
        userCleanup: userStore.reset,
        navigate: () => replace({ name: CRouteNames.home }),
      })

      closeAction()
    }

    return {
      componentName,
      userNameInitials,
      userName,
      userNameChanged,
      logOutAction: logOutAction,
    }
  },
})
</script>
