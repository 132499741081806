<template>
  <CardMenuBaseAction
    v-if="showAction"
    aria-label="Request a create collaboration URL"
    @click="createCollaborationUrl(cardData)"
  >
    <UploadIcon class="mr-2 h-5 w-5" />
    Invite to collaborate
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuActionCreateCollaborationUrl'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'
import { Card } from '@/types'
import { UploadIcon } from '@heroicons/vue/outline'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { useClipboard } from '@vueuse/core'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import { requestCollaborationUrl } from '@/domain/cards/services/cardClient'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { getMetaDataValue, resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from '@/app/services/metaDataClient'
import { truncate } from 'lodash-es'

const props = withDefaults(defineProps<{ cardData: Card }>(), {
  cardData: {} as Card,
})

const { copy: copyToClipBoard } = useClipboard({
  legacy: location.protocol === 'http:',
})

const { removeExposedNotification, exposeOneNotification } = useNotificationsStore()

const createCollaborationUrl = async (card: Card) => {
  const response = await requestCollaborationUrl(card.id)
  const url = `${window.location.origin}/share-links/${response.data?.id as string}/apply`

  // noinspection ES6MissingAwait
  copyToClipBoard(url) // no await to be compatible with Safari

  const title = computed(() => {
    const titleMetaData = resolveMetaDataFromCard(card, metaDataTypes.titleId)
    const title = getMetaDataValue(titleMetaData)
    return truncate(title, { length: 50, omission: '...' })
  })

  const notification = createNotification(
    `A one-time link to "${title.value}" has been copied to the clipboard. It will give full access to edit this topic together with you.`,
    'success',
    {
      text: 'Copy link again',
      doAct: async () => {
        // noinspection ES6MissingAwait
        copyToClipBoard(url) // no await to be compatible with Safari
        await removeExposedNotification()
      },
    },
  )
  exposeOneNotification(notification, 10)
  useCardsStore().popCardFromActiveCardHistory()
}

const { isReadOnly } = storeToRefs(useCardsStore())
const showAction = computed(() => {
  return !isReadOnly.value && props.cardData?.attributes?.cardType === 'topic'
})
</script>

<style scoped></style>
