<template>
  <BaseDropDown
    :class="componentName"
    :has-custom-dropdown="true"
    @closed="closeNotificationMenu"
  >
    <template #menuButton="{ toggleMenu }">
      <button class="relative flex" @click="toggleMenu">
        <span class="sr-only">Open options</span>
        <span
          v-if="unreadNotifications > 0"
          class="absolute right-0 top-0 flex h-4 w-4 -translate-y-1/3 translate-x-1/4 items-center justify-center rounded-full bg-state-warning text-[10px] leading-none text-base-content"
        >
          {{ unreadNotifications }}
        </span>
        <BellIcon class="h-6 w-6" />
      </button>
    </template>
    <template #items>
      <NotificationStack />
    </template>
  </BaseDropDown>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue'
import BaseDropDown from '@/app/components/BaseDropDown.vue'
import { BellIcon } from '@heroicons/vue/outline'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import { storeToRefs } from 'pinia'
import NotificationStack from '@/domain/notifications/components/NotificationStack.vue'

export const componentName = 'NotificationMenu'
export default defineComponent({
  name: componentName,
  components: {
    BaseDropDown,
    BellIcon,
    NotificationStack,
  },
  setup() {
    const notificationStore = useNotificationsStore()
    const { notifications } = storeToRefs(notificationStore)
    const unreadNotifications = computed(() => notifications?.value?.length ?? 0)

    onBeforeMount(async () => {
      await notificationStore.refresh()
    })

    return {
      componentName,
      notifications,
      unreadNotifications,
      closeNotificationMenu: () => {
        // eslint-disable-next-line no-console
        console.log('⚠️', 'toggle button clicked')
      },
    }
  },
})
</script>
