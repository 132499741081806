<template>
  <div
    :id="props.layoutId"
    class="relative z-0 flex w-screen flex-col"
    :class="componentName"
  >
    <div
      class="relative z-20 flex justify-center items-center"
      :class="[
        ...props.navigation.containerClasses,
        ...(isScrolledToTop ? [] : props.navigation.whenScrolledClasses),
      ]"
    >
      <slot name="navigation" :isScrollContainerScrolledToTop="isScrolledToTop" />
    </div>
    <div
      :id="props.scrollContainer.id"
      ref="scrollableContainer"
      class="relative z-0"
      :class="[...props.scrollContainer.classes]"
    >
      <slot name="scrollable-container" />
      <Footer />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'ScrollableContainerLayout'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useInputFix } from '@/app/composables/useInputFix'
import { useScrollableContainer } from '@/app/composables/useScrollableContainer'
import { useUAParser } from '@/app/composables/useUAParser'
import { useScroll } from '@vueuse/core'
import Footer from '@/app/components/Footer.vue'
import type { TScrollableContainerLayoutProps } from '@/app/contracts/TScrollableContainerLayoutProps'

const props = withDefaults(defineProps<TScrollableContainerLayoutProps>(), {
  layoutId: 'scrollable-layout',
  navigation: () => {
    return {
      containerClasses: [],
      whenScrolledClasses: [],
    }
  },
  rootItemsSelectors: () => ['html', 'body'],
  scrollContainer: () => {
    return {
      id: 'scrollable-container',
      classes: [],
    }
  },
  inputs: () => {
    return {
      selectors: ['input', 'textarea'],
      scrollTargetSelector: '.input-scroll-target',
    }
  },
  hasFooter: false,
})

useScrollableContainer(
  `#${props.scrollContainer.id}`,
  props.rootItemsSelectors,
  useUAParser().isMobile,
)

useInputFix(props.inputs.selectors, useUAParser().isMobile, {
  scrollableContainer: `#${props.scrollContainer.id}`,
  scrollTarget: props.inputs.scrollTargetSelector,
  scrollSpacer: { id: 'scroll-spacer', classes: ['shrink-0'] },
})

const scrollableContainer = ref(null)
const { y: scrollableContainerScrollY } = useScroll(scrollableContainer)
const isScrolledToTop = computed(() => {
  return scrollableContainerScrollY.value < 1
})
</script>

<style scoped></style>
