import { RouteRecordRaw } from 'vue-router'
import { Environment } from '@/app/support/environment'
import { CRouteNames } from '@/app/contracts/CRouteNames'

export const blockListRoutes: RouteRecordRaw[] = [
  {
    path: '/pblocklist',
    component: () => import('@/domain/blockList/components/PBlocks.vue'),
    name: 'pblocklist',
    beforeEnter: async (to, from, next) => {
      if (Environment.isProduction()) {
        next({ name: CRouteNames.home, replace: true })
        return
      }
      next()
    },
  } as RouteRecordRaw,
]
