/* eslint-disable prefer-rest-params,@typescript-eslint/ban-ts-comment,no-console */
import { useConfig } from '@/app/services/useConfig'
import { createApp, nextTick } from 'vue'
import App from '@/App.vue'
import './app/assets/app.css'
import { createPinia } from 'pinia'
import { router } from '@/app/services/router'
import * as Fathom from 'fathom-client'
import 'tippy.js/dist/tippy.css'
import { GlobalStatePlugin } from '@/app/support/globalStatePlugin'
import { Environment } from '@/app/support/environment'
import FeedbackWidget from '@/domain/FeedbackWidget'
import { flare } from '@flareapp/flare-client'
import { flareVue } from '@flareapp/flare-vue'
import { PosthogPlugin } from '@/tooling/posthog.plugin'
import posthog from 'posthog-js'
import { createHead } from '@unhead/vue'
import PrimeVue from 'primevue/config'
import Lara from '@/domain/directory/support/multiselect-preset/index'
import { plugin as VueTippy } from 'vue-tippy'
import { createGtm } from '@gtm-support/vue-gtm'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { useNavigator } from '@/app/composables/useNavigator'

const config = useConfig()
config.init(
  // @ts-ignore
  window?.___config,
)

if (!Environment.isProduction()) {
  console.info('⚠️ Environment:', config.get().environment)
  console.log('👇 Version')
  console.table(config.get().version)
}

if (Environment.isLocal()) {
  console.log('👇 Services')
  const services = {}
  Object.keys(config.get()).forEach((key) => {
    services[key] = JSON.stringify(config.get()[key])
  })
  console.table(services)
}

if (config.get().fathom.isEnabled) {
  Fathom.load(config.get().fathom.siteId, {
    url: config.get().fathom?.url,
    spa: 'history',
  })
}

const app = createApp(App)
app.use(createHead())
app.use(PrimeVue, {
  unstyled: true,
  pt: Lara,
})

app.use(
  VueTippy,
  // optional
  {
    component: 'Tippy', // => <tippy/>
  },
)

const flareConfig = config.get().flare
if (flareConfig.isEnabled && flareConfig.projectKey.length) {
  flare.light(flareConfig.projectKey, true)
  flare.addContext('version', config.get().version)
  flare.beforeSubmit = (report) => {
    report.context.version = config.get().version
    return report
  }
  app.use(flareVue)
}

if (Environment.isLocal() || Environment.isMr()) {
  app.use(GlobalStatePlugin)
}

app.use(router).use(createPinia())

if (config.get().yBug.isEnabled) {
  app.use(FeedbackWidget.widget, FeedbackWidget.initializeSettings())
}

if (config.get().posthog.isEnabled) {
  app.use(PosthogPlugin, config.get().posthog)
  router.afterEach((to) => {
    // noinspection JSIgnoredPromiseFromCall
    nextTick(() => {
      posthog.capture('$pageview', {
        $current_url: to.fullPath,
      })
    })
  })
}

if (config.get().gtm.isActivated) {
  app.use(
    createGtm({
      id: config.get().gtm.id,
      queryParams: {
        gtm_auth: config.get().gtm.auth,
        gtm_preview: config.get().gtm.preview,
        gtm_cookies_win: config.get().gtm.cookiesWin,
      },
      defer: true,
      compatibility: false,
      nonce: 'cb066a3a-924c-43d6-9953-7875272e5c02',
      enabled: config.get().gtm.isEnabled, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: config.get().gtm.debug,
      loadScript: true,
      // @ts-ignore
      vueRouter: router,
      ignoredViews: [],
      trackOnNextTick: false,
    }),
  )
}

useAuthentication().init(router.resolve)
useNavigator().init(router)

app.mount('#root')
