import { computed, readonly, ref } from 'vue'
import { EExtendDocumentProgressState } from '@/domain/cards/contracts/EExtendDocumentProgressState'
import type { TExtendDocumentProgressStore } from '@/domain/cards/composables/TExtendDocumentProgressStore'

const _store: TExtendDocumentProgressStore = {}
export const useExtendDocumentProgress = () => {
  const _documentId = ref<string | undefined>(undefined)

  return {
    init: (documentId?: string) => {
      if (!_documentId.value && documentId) {
        _documentId.value = documentId
      }

      if (_documentId.value && !_store[_documentId.value]) {
        _store[_documentId.value] = ref(EExtendDocumentProgressState.new)
      }
    },
    store: readonly(_store),
    documentId: computed(() => _documentId.value),
    status: computed(() => _documentId.value && _store[_documentId.value].value),
    isGenerating: computed(() => {
      if (_documentId.value) {
        return _store[_documentId.value].value === EExtendDocumentProgressState.inProgress
      }

      return false
    }),
    start: () => {
      if (_documentId.value) {
        _store[_documentId.value].value = EExtendDocumentProgressState.inProgress
      }
    },
    done: () => {
      if (_documentId.value) {
        _store[_documentId.value].value = EExtendDocumentProgressState.done
      }
    },
    destroy: () => {
      if (_documentId.value && !_store[_documentId.value]) {
        delete _store[_documentId.value]
      }
    },
  }
}
