import type { RouteRecordRaw, RouteRecordRedirectOption } from 'vue-router'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import PDomain from '@/domain/domains/components/PDomain.vue'

export const domainsRoutes: RouteRecordRaw[] = [
  {
    path: '/domains/:slug',
    name: CRouteNames.domainPages,
    component: PDomain,
  },
  {
    path: '/tokenize-it',
    redirect: () => {
      return { name: CRouteNames.domainPages, params: { slug: 'tokenize-it' } }
    },
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
] satisfies RouteRecordRaw[]
