<template>
  <ScrollableContainerLayout
    layout-id="scrollable-layout"
    :navigation="{ containerClasses: ['h-[52px]'], whenScrolledClasses: ['shadow-lg'] }"
    :root-items-selectors="['html', 'body', '#root', '#scrollable-layout']"
    :scroll-container="{ id: 'scrollable-container', classes: ['flex', 'flex-col'] }"
    :inputs="{
      selectors: ['input', 'textarea'],
      scrollTargetSelector: '.input-scroll-target',
    }"
    :has-footer="true"
  >
    <template #navigation>
      <MainNavigation
        class="px-4 bg-base-100 max-w-7xl"
        :show-logo="true"
        :logo-container-classes="['']"
      />
    </template>
    <template #scrollable-container>
      <SectionLayout width="narrow" :visualize-width="false" tag="div">
        <div class="grow-0 h-12 md:h-40"></div>
        <ScrollTarget :scroll-target-id="SCROLL_TARGET_ID" />
        <TextHero :remote-config="{ searchType: 'route', shouldFetch: true }" />
        <PromptInput
          :scroll-target-id="SCROLL_TARGET_ID"
          :remote-config="{ searchType: 'route', shouldFetch: true }"
          :is-auto-submit-enabled="true"
        />
        <div class="shrink-0 h-[2vh] sm:h-[32vh] md:h-[32vh] lg:h-[10vh] 2xl:h-[32vh]" />
        <DirectorySection :use-fake-data="false" />
      </SectionLayout>

      <Content
        v-if="pageFetchOutcome === EFetchOutcome.SHOW_CONTENT"
        :model="pageConfig.modelName"
        :content="pageContent"
        :api-key="pageConfig.apiKey"
        :custom-components="pageConfig.getComponents()"
      />

      <div class="shrink-0 flex-grow min-h-[120px]" />
    </template>
  </ScrollableContainerLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PLanding'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import ScrollableContainerLayout from '@/app/components/ScrollableContainerLayout.vue'
import MainNavigation from '@/app/components/MainNavigation.vue'
import { onMounted } from 'vue'
import PromptInput from '@/domain/prompt/components/PromptInput.vue'
import { usePromptFocus } from '@/domain/prompt/composables/usePromptFocus'

import { EFetchOutcome } from '@/app/contracts/EFetchOutcome'
import { Content, isEditing } from '@builder.io/sdk-vue'
import { useOnePageEntry } from '@/app/composables/useOnePageEntry'

import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import ScrollTarget from '@/domain/prompt/components/ScrollTarget.vue'
import TextHero from '@/domain/Content/components/TextHero.vue'
import { baseComponentsRegistrationsForBuilderIo } from '@/domain/Content/support/baseComponentsRegistrationsForBuilderIo'
import DirectorySection from '@/domain/directory/components/DirectorySection.vue'

const { SCROLL_TARGET_ID } = usePromptFocus()

const {
  config: pageConfig,
  content: pageContent,
  fetchOutcome: pageFetchOutcome,
  doFetch,
} = useOnePageEntry('page', baseComponentsRegistrationsForBuilderIo(isEditing()))

onMounted(async () => {
  await doFetch()
})
</script>

<style></style>
