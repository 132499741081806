import { computed } from 'vue'
import { resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from '@/app/services/metaDataClient'
import { Card } from '@/types'
import { ComputedRef } from 'vue'

export const createIsDetailANodeWithAdditionalStepsInProcess = (
  cardData: Card,
): ComputedRef<boolean> => {
  return computed(() => {
    const detailANodeWithAdditionalStepsMetadata = resolveMetaDataFromCard(
      cardData,
      metaDataTypes.detailANodeWithAdditionalSteps,
    )

    if (!detailANodeWithAdditionalStepsMetadata) {
      return false
    }

    return (
      detailANodeWithAdditionalStepsMetadata?.attributes.payload?.status &&
      !['undefined', 'nodes-created'].includes(
        detailANodeWithAdditionalStepsMetadata?.attributes.payload.status,
      )
    )
  })
}
