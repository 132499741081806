<template>
  <SectionLayout width="narrow" :visualize-width="false" tag="section">
    <div
      class="flex items-start gap-x-2 md:flex-row md:space-y-0 md:gap-x-4 md:gap-y-8 w-full pb-4 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <aside class="h-full flex flex-col">
        <figure class="flex justify-center items-center">
          <img
            loading="lazy"
            :src="props.currentExpert?.photo"
            :alt="`Profile image of ${props.currentExpert?.firstname} ${props.currentExpert?.lastname}`"
            class="object-cover w-10 sm:w-16 md:w-28 aspect-sqare rounded-full"
          />
        </figure>
      </aside>
      <article
        class="relative text-left w-full flex flex-col justify-between lg:col-span-2 xl:col-span-3 min-h-[112px]"
      >
        <div class="flex flex-col max-w-[540px]">
          <div class="flex gap-x-2 flex-wrap justify-start items-end">
            <h1 class="text-2xl font-semibold text-base-content">
              {{ props.currentExpert?.firstname }} {{ props.currentExpert?.lastname }}
            </h1>
            <p class="text-base font-normal text-base-400">
              @{{ props.currentExpert?.slug }}
            </p>
          </div>
          <p
            class="mt-2 text-base leading-normal font-medium text-base-500 line-clamp-3 overflow-hidden overflow-ellipsis"
            v-html="props.currentExpert?.slogan"
          />
        </div>

        <nav class="flex justify-between mt-4">
          <span class="flex justify-center gap-x-2 items-center">
            <a
              v-for="item in props.currentExpert?.urls ?? []"
              :key="item.url"
              :href="item.url"
              target="_blank"
              class="text-base-400 hover:text-state-error-focus"
            >
              <SocialIcons :type="item.type" />
            </a>
          </span>
          <FollowExpertButton class="lg:hidden" :is-enabled="false" />
        </nav>
        <FollowExpertButton
          class="hidden lg:flex lg:absolute lg:inset-y-0 lg:right-0 h-full"
          :is-enabled="false"
        />
      </article>
    </div>
  </SectionLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'ExpertHeroSection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import type { TExpert } from '@/domain/directory/contracts/TExpert'
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import SocialIcons from '@/app/components/SocialIcons.vue'
import FollowExpertButton from '@/domain/directory/components/FollowExpertButton.vue'

const props = defineProps<{ currentExpert: TExpert | null }>()
</script>

<style scoped></style>
