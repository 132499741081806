<template>
  <p
    v-if="!isEmpty(props?.content?.category)"
    :class="categoryClassesComputed"
    v-html="props.content?.category"
  />
  <component
    :is="props.titleTag ?? 'div'"
    v-if="!isEmpty(props?.content?.title)"
    :class="titleClassesComputed"
    v-html="props.content?.title"
  />
  <p
    v-if="!isEmpty(props?.content?.subline)"
    :class="sublineClassesComputed"
    v-html="props.content?.subline"
  />
  <p
    v-if="!isEmpty(props?.content?.claim)"
    :class="claimClassesComputed"
    v-html="props?.content?.claim"
  />
</template>

<script lang="ts">
export const componentName = 'TextHeading'
</script>

<script lang="ts" setup>
import { computed, type ComputedRef, defineOptions } from 'vue'
import { isEmpty } from 'lodash-es'
import { cva, type VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

defineOptions({
  name: componentName,
})

type TTitleProp = VariantProps<typeof titleClasses>
const props = withDefaults(
  defineProps<{
    titleTag?: TTitleProp['titleTag']
    content?: {
      category?: string
      title?: string
      subline?: string
      claim?: string
    }
    classes?: {
      category?: string
      title?: string
      subline?: string
      claim?: string
    }
    gap?: string
  }>(),
  {
    titleTag: 'h1',
    content: () => ({
      category: undefined,
      title: undefined,
      subline: undefined,
      claim: undefined,
    }),
    classes: () => ({
      category: undefined,
      title: undefined,
      subline: undefined,
      claim: undefined,
    }),
    gap: 'mt-2',
  },
)

const categoryClasses = cva(['text-base-content'], {
  variants: {
    titleTag: {
      h1: 'text-base font-medium',
      h2: 'text-base font-medium',
      h3: 'text-base font-medium',
      h4: 'text-base font-medium',
    },
  },
})
const categoryClassesComputed = computed(() =>
  twMerge(
    categoryClasses({
      titleTag: props.titleTag,
      class: [props.gap, props.classes.category],
    }),
  ),
)

const titleClasses = cva(['text-base-content'], {
  variants: {
    titleTag: {
      h1: 'font-extrabold text-2xl',
      h2: 'font-extrabold text-xl',
      h3: 'font-extrabold text-lg',
      h4: 'font-extrabold text-md',
    },
  },
})

const titleClassesComputed: ComputedRef<string> = computed(() =>
  twMerge(
    titleClasses({
      titleTag: props?.titleTag as TTitleProp['titleTag'],
      class: [props.classes.title, props.gap],
    }),
  ),
)

const sublineClasses = cva(['text-base-content'], {
  variants: {
    titleTag: {
      h1: 'text-lg font-semibold leading-tight',
      h2: 'text-lg font-semibold leading-tight',
      h3: 'text-lg font-semibold leading-tight',
      h4: 'text-lg font-semibold leading-tight',
    },
  },
})
const sublineClassesComputed = computed(() =>
  twMerge(
    sublineClasses({
      titleTag: props?.titleTag as TTitleProp['titleTag'],
      class: [props.classes.subline, props.gap],
    }),
  ),
)

const claimClasses = cva(['text-base-500', 'pt-2'], {
  variants: {
    titleTag: {
      h1: 'text-base',
      h2: 'text-base',
      h3: 'text-base',
      h4: 'text-base',
    },
  },
})
const claimClassesComputed = computed(() =>
  twMerge(
    claimClasses({
      titleTag: props?.titleTag as TTitleProp['titleTag'],
      class: [props.classes.claim, props.gap],
    }),
  ),
)
</script>

<style scoped></style>
