import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'
import { documents } from '@/app/services/miljnApiEndpointsWeb2'

export const createDocument = async (title: string) => {
  const instance = miljnApiBaseClientFactory()
  const requestBody = {
    data: {
      type: 'documents',
      attributes: {
        title: title,
        status: 'null',
      },
    },
  }

  const requestConfig = setPreferHeader({}, 'documents')

  const response = await instance.post(documents.index, requestBody, requestConfig)

  return response?.data?.data ?? null
}

export const getDocumentStatus = async (id: string) => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'documents')
  const response = await instance.get(
    documents.documentStatusByDocumentId(id),
    requestConfig,
  )
  return response?.data
}
