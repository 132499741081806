<template>
  <BaseFullscreenDialog
    class="bg-base-100"
    :class="componentName"
    :dialog-is-visible="dialogIsVisible"
    :disable-close-button="false"
    @dialogVisibilityChanged="dialogIsVisible = $event"
  >
    <template #logo>
      <MainNavigationLogo :class="[]" />
    </template>

    <template #backlink="{ closeDialog }">
      <button
        class="text-base-400 group-hover:text-base-500 group-hover:underline pr-2 md:pr-4"
        @click="[goToHome(), closeDialog()]"
      >
        {{ content.navigateToDashboard }}
      </button>
    </template>

    <template #dialogContent>
      <div
        class="flex w-full justify-center px-5 sm:px-12 pt-24 xs:pt-40 h-full"
        @click.stop="() => {}"
      >
        <div class="flex h-full w-full max-w-3xl flex-col items-center justify-start">
          <div class="flex flex-row w-full flex-nowrap">
            <span class="block mr-6 grow w-1 bg-base-200" />
            <textarea
              id="dialog"
              ref="promptInputElement"
              v-model="prompt"
              class="m-0 w-full border-0 p-0 focus:outline-none focus:ring-0 focus-visible:outline-none text-3xl text-base-500 placeholder-base-200 md:text-4xl resize-none"
              :style="{
                minHeight: `${minPromptHeight}px`,
              }"
              :placeholder="content.placeholder"
              :maxlength="enableAiSupport ? 2048 : 144"
              @keydown.enter.prevent="insertLineBreak"
            />
          </div>

          <div
            class="mt-10 flex w-full flex-nowrap items-center justify-start space-x-4 md:space-x-6"
          >
            <button
              id="create"
              type="button"
              class="relative flex justify-center rounded-xl px-4 py-2 text-sm text-base-100 md:w-fit"
              :class="[
                ...(isEmptyTitle ? ['!bg-base-300', 'cursor-not-allowed'] : []),
                ...(isCreating
                  ? ['bg-base-200', 'text-base-400']
                  : ['bg-state-error', 'hover:bg-state-error-focus']),
              ]"
              :disabled="isCreating"
              data-testid="create-new-topic"
              @click="performTopicCreation"
            >
              <ProgressAnimation
                v-show="isCreating"
                class="absolute left-5 top-2.5 text-state-warning"
                is-animating
              />
              <span>{{ content.action }}</span>
            </button>
            <span class="hidden cursor-default text-sm text-base-500 md:block">
              press
              <span class="font-semibold">Meta + Enter ↵</span>
            </span>
            <SwitchGroup>
              <div class="flex items-center">
                <Switch
                  v-model="enableAiSupport"
                  :class="enableAiSupport ? 'bg-state-error' : 'bg-base-150'"
                  class="relative inline-flex h-6 w-11 items-center rounded-full"
                >
                  <span class="sr-only">{{ content.aiToggle }}</span>
                  <span
                    :class="enableAiSupport ? 'translate-x-6' : 'translate-x-1'"
                    class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                  />
                </Switch>
                <SwitchLabel class="ml-2 xs:ml-4 text-sm text-base-500 leading-none">
                  {{ content.aiToggle }}
                </SwitchLabel>
              </div>
            </SwitchGroup>
          </div>
        </div>
      </div>
    </template>
  </BaseFullscreenDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseFullscreenDialog from '@/app/components/BaseFullscreenDialog.vue'
import ProgressAnimation from '@/app/components/ProgressAnimation.vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export const componentName = 'CreateNewTopicDialog'

export default defineComponent({
  name: componentName,
  components: {
    BaseFullscreenDialog,
    ProgressAnimation,
    SwitchLabel,
    Switch,
    SwitchGroup,
  },
})
</script>

<script setup lang="ts">
import { useCreateNewTopicDialogStore } from '@/domain/createTopic/services/useCreateNewTopicDialogStore'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { computed, ref, watch } from 'vue'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import MainNavigationLogo from '@/app/components/MainNavigationLogo.vue'
import { isEmpty } from 'lodash-es'
import { useMagicKeys, useTextareaAutosize } from '@vueuse/core'

const useCreateNewTopicDialog = useCreateNewTopicDialogStore()
const { close: closeDialog } = useCreateNewTopicDialog
const { dialogIsVisible } = storeToRefs(useCreateNewTopicDialog)
const router = useRouter()
const topicsStore = useTopicsStore()
const { addNewTopic } = topicsStore
const { isCreating } = storeToRefs(topicsStore)

const enableAiSupport = ref(true)

// @todo add posthog event for toggling AI support
// @todo find the line break preventing event listener

const { textarea: promptInputElement, input: prompt } = useTextareaAutosize()
const minPromptHeight = 44

const performTopicCreation = async () => {
  if (prompt.value === '') {
    return
  }

  await addNewTopic(
    prompt.value,
    async (topicId?: string) => {
      const navigationTarget = enableAiSupport.value
        ? { name: CRouteNames.dashboard }
        : { name: CRouteNames.topic, params: { topicId: topicId } }

      await router.push(navigationTarget)
      closeDialog()
    },
    enableAiSupport.value,
  )

  prompt.value = ''
}

const goToHome = () => {
  router.push({ name: CRouteNames.dashboard })
}

const content = {
  navigateToDashboard: 'Go to Dashboard',
  placeholder: 'What do you want get done today?',
  action: 'Create',
  aiToggle: 'Enable AI support',
  warning: {
    title: 'BETA version warning',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum.',
  },
}
const isEmptyTitle = computed(() => {
  return isEmpty(prompt.value)
})

const insertLineBreak = () => {
  if (isEmpty(prompt.value)) {
    prompt.value = '\n'
    return
  }

  prompt.value = prompt.value + '\n'
}

const keys = useMagicKeys()
const metaEnter = keys['Meta+Enter']

watch([metaEnter], async ([newMetaEnter]) => {
  if (newMetaEnter && !isEmpty(prompt.value)) {
    await performTopicCreation()
  }
})
</script>
