import Fuse from 'fuse.js'

const defaultOptions = {
  isCaseSensitive: false,
  // includeScore: true,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  // threshold: 0.6,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ['relationships.metadata.data.attributes.payload.value'],
}

export const createFuse = (list, options?: any) => {
  return new Fuse(list, options ?? defaultOptions)
}
