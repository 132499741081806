<template>
  <div
    v-if="isProcessing"
    aria-label="Processing Badge"
    class="flex space-x-2 rounded-full border px-2 py-1 text-sm font-medium"
    :class="[componentName]"
  >
    <ProgressAnimation
      aria-label="Processing Badge"
      :class="[componentName]"
      :is-animating="true"
    />
    <span :class="minimizeOnMobile ? ['hidden', 'lg:block'] : []">{{ label }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'NodeProcessBadge'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { withDefaults } from 'vue'
import ProgressAnimation from '@/app/components/ProgressAnimation.vue'

withDefaults(
  defineProps<{
    label: string
    isProcessing: boolean
    minimizeOnMobile?: boolean
  }>(),
  {
    label: 'Loading ...',
    minimizeOnMobile: false,
  },
)
</script>
