<template>
  <ScrollableContainerLayout
    layout-id="scrollable-layout"
    :navigation="{ containerClasses: ['h-[52px]'], whenScrolledClasses: ['shadow-lg'] }"
    :root-items-selectors="['html', 'body', '#root', '#scrollable-layout']"
    :scroll-container="{ id: 'scrollable-container', classes: ['flex', 'flex-col'] }"
    :inputs="{
      selectors: ['input', 'textarea'],
      scrollTargetSelector: '.input-scroll-target',
    }"
    :has-footer="true"
  >
    <template #navigation>
      <MainNavigation
        class="px-4 bg-base-100 max-w-7xl"
        :show-logo="true"
        :logo-container-classes="['']"
      />
    </template>
    <template #scrollable-container>
      <THttpErrorComponent
        v-if="fetchOutcome === EFetchOutcome.SHOW_FALLBACK"
        code="404"
        :action="{ title: 'Back to Homepage', target: '/' }"
      />

      <ExpertHeroSection
        v-if="selectedExpertIds.length > 0"
        :current-expert="currentExpert"
        class="mt-8"
      />

      <SectionLayout
        v-if="FEATURES.enablePromptInput"
        width="narrow"
        :visualize-width="false"
        tag="div"
        class="my-[8vh]"
      >
        <PromptInput
          v-if="selectedExpertIds.length > 0"
          :remote-config="{ searchType: 'route', shouldFetch: true }"
          :is-auto-submit-enabled="true"
          class="mb-[4vh]"
          :placeholder="`How do I as ${currentExpert?.firstname} ${currentExpert?.lastname}...`"
        />
      </SectionLayout>

      <DirectorySection
        v-if="selectedExpertIds.length > 0"
        :selected-expert-ids="selectedExpertIds"
        :use-fake-data="false"
        :enable-category-selector="FEATURES.enableCategorySelector"
      />

      <div class="shrink-0 flex-grow min-h-[32px] md:min-h-[64px]" />
    </template>
  </ScrollableContainerLayout>

  <div>Profile</div>
  <div>{{ currentRoute.name }}</div>
  <div>{{ currentRoute.params?.slug ?? 'undefined' }}</div>
  <div>{{ currentExpert?.id }}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PExpert'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import { computed, onMounted, ref } from 'vue'
import type { TExpert } from '@/domain/directory/contracts/TExpert'
import type { TExpertData } from '@/domain/directory/contracts/TExpertData'
import { expertDataTransformer } from '@/domain/directory/services/expertDataTransformer'
import { EFetchOutcome } from '@/app/contracts/EFetchOutcome'
import THttpErrorComponent from '@/app/components/THttpErrorComponent.vue'
import MainNavigation from '@/app/components/MainNavigation.vue'
import ScrollableContainerLayout from '@/app/components/ScrollableContainerLayout.vue'
import DirectorySection from '@/domain/directory/components/DirectorySection.vue'
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import PromptInput from '@/domain/prompt/components/PromptInput.vue'
import ExpertHeroSection from '@/domain/directory/components/ExpertHeroSection.vue'

const FEATURES = {
  enablePromptInput: false,
  enableCategorySelector: false,
}

const { currentRoute } = useRouter()

const apiKey = useConfig().get().builderIO.publicApiKey
const _fetchOutcome = ref<EFetchOutcome>(EFetchOutcome.UNDEFINED)

const fetchExpert = async (
  apiKey: string,
  expertSlug?: string,
): Promise<TExpert | null> => {
  if (!expertSlug) {
    return null
  }

  const firstExpert = (await fetchOneEntry({
    apiKey: useConfig().get().builderIO.publicApiKey,
    model: CBuilderModels.Experts,
    query: {
      data: {
        slug: { $eq: expertSlug },
      },
    },
  })) as TExpertData | null

  if (!firstExpert) {
    return null
  }

  return expertDataTransformer(firstExpert)
}

const currentExpert = ref<TExpert | null>(null)

onMounted(async () => {
  currentExpert.value = await fetchExpert(
    apiKey,
    (currentRoute.value.params as { slug?: string }).slug,
  )
  _fetchOutcome.value =
    currentExpert.value !== null
      ? EFetchOutcome.SHOW_CONTENT
      : EFetchOutcome.SHOW_FALLBACK
})

const selectedExpertIds = computed(() =>
  currentExpert.value ? [currentExpert.value?.id] : [],
)
const fetchOutcome = computed(() => _fetchOutcome.value)
</script>

<style scoped></style>
