export enum CardStateIds {
  inProgress = '220fd869-836d-423c-93c7-57e6e87d990b',
  completed = 'd1a08ff4-14f7-4035-8ff6-3f3ae448d351',
  waiting = 'fb0e8025-130d-462a-beb3-e0c340ece072',
  problem = '296bd399-be21-49c5-9aa9-9fe5efc2cbac',
  noStatus = '',
}

export enum CardStateBgClasses {
  inProgress = 'bg-card-state-in-progress',
  completed = 'bg-card-state-completed',
  waiting = 'bg-card-state-waiting',
  problem = 'bg-card-state-problem',
  noStatus = 'bg-card-state-no-status',
}

export const resolveDefaultCardState = () => resolveCardState(CardStateIds.noStatus)

export const resolveCardState = (cardStateId: CardStateIds) =>
  cardStates.find((state) => state?.value === cardStateId)

export const cardStates = [
  {
    label: 'In progress',
    value: CardStateIds.inProgress,
    title: 'text-base-500 hover:!text-base-900 focus:!text-base-900',
    marker: CardStateBgClasses.inProgress,
    background: CardStateBgClasses.inProgress,
    sharedBadge: 'text-base-700 border-base-400',
    icon: 'text-base-500 hover:text-base-900',
  },
  {
    label: 'Completed',
    value: CardStateIds.completed,
    title: 'text-base-500 hover:!text-base-900 focus:!text-base-900',
    marker: CardStateBgClasses.completed,
    background: CardStateBgClasses.completed,
    sharedBadge: 'text-base-700 border-base-400',
    icon: 'text-base-500 hover:text-base-900',
  },
  {
    label: 'Waiting',
    value: CardStateIds.waiting,
    title: 'text-base-500 hover:!text-base-900 focus:!text-base-900',
    marker: CardStateBgClasses.waiting,
    background: CardStateBgClasses.waiting,
    sharedBadge: 'text-base-700 border-base-400',
    icon: 'text-base-500 hover:text-base-900',
  },
  {
    label: 'Problem',
    value: CardStateIds.problem,
    title: 'text-base-150 hover:!text-base-100 focus:!text-base-100',
    marker: CardStateBgClasses.problem,
    background: CardStateBgClasses.problem,
    sharedBadge: 'border-base-200 text-base-500',
    icon: 'text-base-150 hover:!text-base-100',
  },
  {
    label: 'No status',
    value: CardStateIds.noStatus,
    title: 'text-base-500 hover:!text-base-900 focus:!text-base-900',
    marker: '!bg-transparent',
    background: CardStateBgClasses.noStatus,
    sharedBadge: 'border-base-300 text-base-content-70',
    icon: 'text-base-500 hover:text-base-900',
  },
]
