<template>
  <ScrollableContainerLayout
    layout-id="scrollable-layout"
    :navigation="{ containerClasses: ['h-[52px]'], whenScrolledClasses: ['shadow-lg'] }"
    :root-items-selectors="['html', 'body', '#root', '#scrollable-layout']"
    :scroll-container="{ id: 'scrollable-container', classes: ['flex', 'flex-col'] }"
    :inputs="{
      selectors: ['input', 'textarea'],
      scrollTargetSelector: '.input-scroll-target',
    }"
    :has-footer="true"
  >
    <template #navigation>
      <MainNavigation
        class="px-4 bg-base-100 max-w-7xl"
        :show-logo="true"
        :logo-container-classes="['']"
      />
    </template>
    <template #scrollable-container>
      <THttpErrorComponent
        v-if="pageFetchOutcome === EFetchOutcome.SHOW_FALLBACK"
        code="404"
        :action="{ title: 'Back to Homepage', target: '/' }"
      />

      <Content
        v-if="pageFetchOutcome === EFetchOutcome.SHOW_CONTENT"
        :model="pageConfig.modelName"
        :content="pageContent"
        :api-key="pageConfig.apiKey"
        :custom-components="pageConfig.getComponents()"
      />

      <div class="shrink-0 flex-grow min-h-[32px] md:min-h-[64px]" />
    </template>
  </ScrollableContainerLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PDomain'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import MainNavigation from '@/app/components/MainNavigation.vue'
import ScrollableContainerLayout from '@/app/components/ScrollableContainerLayout.vue'
import { Content, isEditing } from '@builder.io/sdk-vue'
import { onMounted, watch } from 'vue'
import THttpErrorComponent from '@/app/components/THttpErrorComponent.vue'
import { useOnePageEntry } from '@/app/composables/useOnePageEntry'
import { EFetchOutcome } from '@/app/contracts/EFetchOutcome'
import { useHead } from '@unhead/vue'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import { baseComponentsRegistrationsForBuilderIo } from '@/domain/Content/support/baseComponentsRegistrationsForBuilderIo'

const {
  config: pageConfig,
  content: pageContent,
  fetchOutcome: pageFetchOutcome,
  doFetch,
} = useOnePageEntry(
  CBuilderModels.Page,
  baseComponentsRegistrationsForBuilderIo(isEditing()),
)

onMounted(async () => {
  await doFetch()
})

watch(pageContent, (newPageContent) => {
  if (!newPageContent) {
    return
  }

  useHead({
    title: `Miljn – ${newPageContent.data?.title}`,
    meta: [
      {
        name: 'description',
        content: newPageContent.data?.description,
      },
    ],
  })
})
</script>

<style scoped></style>
