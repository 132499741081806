import { log } from '../services/errorService'

type ErrorOptions<Return> = {
  errorMessage?: string
  providerName?: string
  defaults?: Return
}

export const injectionStateFactory =
  <Return>(useInjectedState: () => Return, errorOptions?: ErrorOptions<Return>) =>
  (): Return & {
    error?: string
  } => {
    const injectionState = useInjectedState()
    if (injectionState === undefined) {
      const error =
        errorOptions?.errorMessage ??
        `Please call \`${
          errorOptions?.providerName ?? 'provider'
        }\` on the appropriate parent component`
      log(error)

      return {
        ...(errorOptions?.defaults as Return),
        error,
      }
    }
    return injectionState as any
  }
