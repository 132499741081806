export const apiPrefix = ''

export const usersBasename = 'users'
export const users = {
  index: `${apiPrefix}/${usersBasename}`,
  me: `${apiPrefix}/${usersBasename}/me`,
  byUserId: (userId: string) => `${apiPrefix}/${usersBasename}/${userId}`,
}

export const blocksBasename = 'blocks'
export const blocks = {
  index: `${apiPrefix}/${blocksBasename}`,
  byBlockId: (blockId: string) => `${apiPrefix}/${blocksBasename}/${blockId}`,
  byBlockIds: (blockIds: Array<string>) =>
    `${apiPrefix}/${blocksBasename}?filter[id]=[${blockIds}]`,
  byCardId: (cardId: string) => `${apiPrefix}/${blocksBasename}?filter[card]=${cardId}`,
  restoreByBlockId: (blockId: string) =>
    `${apiPrefix}/${blocksBasename}/${blockId}/restore`,
}

export const blockTypesBasename = 'block-types'
export const blockTypes = {
  index: `${apiPrefix}/${blockTypesBasename}`,
  byBlockTypeId: (blockTypeId: string) =>
    `${apiPrefix}/${blockTypesBasename}/${blockTypeId}`,
}

export const metaDataTypesBasename = 'metadata-types'
export const metaDataTypes = {
  index: `${apiPrefix}/${metaDataTypesBasename}`,
  byMetaDataTypeId: (metaDataId: string) =>
    `${apiPrefix}/${metaDataTypesBasename}/${metaDataId}`,
}

export const metaDataBasename = 'metadata'
export const metaData = {
  index: `${apiPrefix}/${metaDataBasename}`,
  byMetaDataId: (metaDataId: string) => `${apiPrefix}/${metaDataBasename}/${metaDataId}`,
  update: (metadataId: string) =>
    `${apiPrefix}/metadata/${metadataId}?include=metadataType`,
}

export const cardsBasename = 'cards'
export const cards = {
  index: `${apiPrefix}/${cardsBasename}`,
  byCardId: (cardId: string) => `${apiPrefix}/${cardsBasename}/${cardId}`,
  restoreByCardId: (cardId: string) => `${apiPrefix}/${cardsBasename}/${cardId}/restore`,
  assignParent: (nodeId: string, targetParentId: string) =>
    `${apiPrefix}/${cardsBasename}/${nodeId}/assign/${targetParentId}`,
  shareByTopicId: (topicId: string) => `${apiPrefix}/${cardsBasename}/${topicId}/share`,
  shareCopyByTopicId: (topicId: string) =>
    `${apiPrefix}/${cardsBasename}/${topicId}/share-copy`,
  blocksOrder: (cardId: string) => `${apiPrefix}/${cardsBasename}/${cardId}/blocks/order`,
  childrenOrder: (cardId: string) =>
    `${apiPrefix}/${cardsBasename}/${cardId}/children/order`,
}

export const documentsBasename = 'documents'
export const documents = {
  index: `${apiPrefix}/${documentsBasename}`,
  byDocumentId: (documentId: string) => `${apiPrefix}/${documentsBasename}/${documentId}`,
  documentStatusByDocumentId: (documentId: string) =>
    `${apiPrefix}/${documentsBasename}/${documentId}/status`,
}

export const applyShareLinksBasename = 'share-links'
export const applyShareLinks = {
  index: `${apiPrefix}/${applyShareLinksBasename}`,
  applySharedLinkByShareId: (shareId: string) =>
    `${apiPrefix}/${applyShareLinksBasename}/${shareId}/apply`,
  sharingInformation: (linkId: string) => `/share-links/${linkId}/information`,
}

export const OpenGraphDocumentBasename = 'open-graph-documents'
export const OpenGraphDocument = {
  create: `${apiPrefix}/${OpenGraphDocumentBasename}`,
}
