import { onMounted, onUnmounted, ref, Ref } from 'vue'

const toPixel = (value: number | string) => `${value}px`

export const useScrollSpacer = (
  containerSelector: string,
  scrollSpacerId = 'scroll-spacer',
  customClasses?: string[],
) => {
  const containerRef: Ref<HTMLElement | null> = ref(null)
  const spacerHeightRef: Ref<number> = ref(
    window.visualViewport !== null ? window.visualViewport.height : window.innerHeight,
  )
  const deactivatedScrollSpacerHeight = '0'

  const scrollSpacerRef: Ref<HTMLElement | null> = ref(null)

  const _create = (
    containerRef: Ref<HTMLElement | null>,
    scrollSpacerRef: Ref<HTMLElement | null>,
  ) => {
    containerRef.value = document.querySelectorAll(containerSelector)[0] as HTMLElement
    if (!containerRef.value) {
      throw new Error(`Could not find container element for '${containerSelector}'`)
    }

    scrollSpacerRef.value = document.createElement('div')
    scrollSpacerRef.value.id = scrollSpacerId
    if (customClasses && customClasses.length > 0 && scrollSpacerRef.value?.classList) {
      scrollSpacerRef.value.classList.add(...customClasses)
    }
    scrollSpacerRef.value.style.height = toPixel(deactivatedScrollSpacerHeight)
    containerRef.value.appendChild(scrollSpacerRef.value)
  }

  const _destroy = (scrollSpacerRef: Ref<HTMLElement | null>) => {
    if (scrollSpacerRef.value !== null && scrollSpacerRef.value.parentElement !== null) {
      scrollSpacerRef.value.parentElement.removeChild(scrollSpacerRef.value)
    }
    scrollSpacerRef.value = null
  }

  onMounted(() => _create(containerRef, scrollSpacerRef))
  onUnmounted(() => _destroy(scrollSpacerRef))

  return {
    activate: () => {
      if (scrollSpacerRef.value !== null) {
        scrollSpacerRef.value.style['height'] = toPixel(spacerHeightRef.value)
      }
    },
    deactivate: () => {
      if (scrollSpacerRef.value !== null) {
        scrollSpacerRef.value.style['height'] = toPixel(deactivatedScrollSpacerHeight)
      }
    },
  }
}
