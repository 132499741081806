<template>
  <Menu as="div" class="relative">
    <MenuButton as="div" class="flex items-center gap-x-2 text-base-500">
      <button
        class="cursor-pointer"
        aria-label="Icon for changing sort order"
        :data-sort-order="order"
        @click.stop.prevent="toggleOrder"
      >
        <component :is="iconComponent" class="h-5 w-5" />
      </button>
      <button
        id="sort-menu"
        aria-label="Sort menu for topics"
        aria-haspopup="true"
        aria-controls="sort-menu"
        class="!leading-none"
      >
        <span class="sr-only">Open options</span>
        <span>
          {{ sortBy }}
        </span>
      </button>
    </MenuButton>

    <Transition
      enter-active-class="transition ease-out origin-top-right duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in origin-top-right duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute -bottom-4 -right-2 z-50 w-56 min-w-[240px] translate-y-full overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
      >
        <MenuItem
          v-for="(item, index) in Object.values(SortOptions)"
          v-slot="{ active, close: closeMenu }"
          :key="index"
          as="template"
        >
          <button
            class="cursor-pointer px-4 py-2 inline-flex w-full"
            :class="active && 'bg-gray-100 text-base-900'"
            aria-label="Sort option"
            @click="setSortOption(item, closeMenu)"
          >
            {{ item }}
          </button>
        </MenuItem>
      </MenuItems>
    </Transition>
  </Menu>
</template>

<script setup lang="ts">
import { SortDescendingIcon, SortAscendingIcon } from '@heroicons/vue/outline'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { computed } from 'vue'
import { useTopicsStore, SortOptions } from '@/app/services/useTopicsStore'
import { storeToRefs } from 'pinia'

const { sortBy, order } = storeToRefs(useTopicsStore())
const iconComponent = computed(() =>
  order.value === 'asc' ? SortAscendingIcon : SortDescendingIcon,
)

const setSortOption = (option: SortOptions, closeMenu?: any) => {
  if (typeof closeMenu === 'function') {
    closeMenu()
  }
  sortBy.value = option
}

const toggleOrder = () => {
  order.value = order.value === 'asc' ? 'desc' : 'asc'
}
</script>
