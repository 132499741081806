import type { RouteRecordRaw, RouteRecordRedirectOption } from 'vue-router'
import { signInRouteGuard } from '@/domain/Authentication/guards/signInRouteGuard'
import PSignIn from '@/domain/Authentication/components/PSignIn.vue'
import { CAuthenticationRouteNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'
import { logoutRouteGuard } from '@/domain/Authentication/guards/logoutRouteGuard'
import { signInWithMagicUriRouteGuard } from '@/domain/Authentication/guards/signInWithMagicUriRouteGuard'
import { useLinkedInAuthCodeStore } from '@/domain/Authentication/composables/useLinkedInAuthCodeStore'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { captureWithUser } from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { useConfig } from '@/app/services/useConfig'
import { useXOAuthStore } from '@/domain/Authentication/composables/useXOAuthStore'
import { EXSignInStages } from '@/domain/Authentication/contracts/EXSignInStages'
import { useXAuthenticationRetry } from '@/domain/Authentication/composables/useXAuthenticationRetry'

export const authenticationRoutes: RouteRecordRaw[] = [
  {
    path: '/sign-in',
    name: CAuthenticationRouteNames.signIn, // todo factor out route names
    component: PSignIn,
    beforeEnter: signInRouteGuard,
  },
  {
    path: '/login',
    name: CAuthenticationRouteNames.login,
    beforeEnter: async (to, from, next) => {
      // @todo add posthog logging
      next({ name: CAuthenticationRouteNames.signIn, replace: true })
      return
    },
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
  {
    path: '/register',
    name: CAuthenticationRouteNames.register,
    beforeEnter: async (to, from, next) => {
      // @todo add posthog logging
      next({ name: CAuthenticationRouteNames.signIn, replace: true })
      return
    },
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
  {
    path: '/logout',
    name: CAuthenticationRouteNames.logout,
    beforeEnter: logoutRouteGuard,
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
  {
    path: '/l/:magicUrlId?',
    name: CAuthenticationRouteNames.signInWithMagicUri,
    beforeEnter: signInWithMagicUriRouteGuard,
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
  {
    path: '/authentication/:provider',
    name: CAuthenticationRouteNames.oAuthCallback,
    beforeEnter: async (to, from, next) => {
      // @warn remove 👇
      if (useConfig().get().environment !== 'production') {
        // eslint-disable-next-line no-console
        console.log('🔄', {
          name: to.name,
          provider: to.params.provider,
          query: { ...to.query },
        })
      }

      if (to.params.provider === 'linkedin') {
        if (
          !to.query.code ||
          useLinkedInAuthCodeStore().hasMatchingState(to.query.state as string)
        ) {
          useAuthentication().setStatus(EAuthenticationProviderSignInFlowStates.FAILED)
          captureWithUser(EAuthenticationEvents.signInFailed, {
            provider: EAuthenticationProviders.LINKEDIN,
            status: useAuthentication().status.value,
            errorMessage: 'LinkedIn token state does not match',
            signInContext: { ...to.query },
            route: to.name as string,
            route_params: { ...to.params },
            route_query: { ...to.query },
          })
          window.close()
        }

        useLinkedInAuthCodeStore().collect(
          to.query.code as string,
          to.query.state as string,
        )

        window.close()
      }

      if (to.params.provider === 'x') {
        if (!to.query.oauth_token || !to.query.oauth_verifier) {
          useAuthentication().setStatus(EAuthenticationProviderSignInFlowStates.FAILED)
          captureWithUser(EAuthenticationEvents.signInFailed, {
            provider: EAuthenticationProviders.X,
            status: useAuthentication().status.value,
            stage: EXSignInStages.REQUEST_OAUTH_TOKEN,
            errorMessage: `X 'oauth_token' or 'oauth_verifier' are missing`,
            signInContext: { ...to.query },
            route: to.name as string,
            route_params: { ...to.params },
            route_query: { ...to.query },
          })
          window.close()
        }

        // eslint-disable-next-line no-console
        await useXOAuthStore().collect(
          to.query.oauth_token as string,
          to.query.oauth_verifier as string,
        )

        useXAuthenticationRetry().increment()
        captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
          provider: EAuthenticationProviders.X,
          status: useAuthentication().status.value,
          stage: EXSignInStages.REQUEST_OAUTH_TOKEN,
          signInContext: { ...to.query },
          route: to.name as string,
          route_params: { ...to.params },
          route_query: { ...to.query },
        })

        window.close()
      }

      captureWithUser(EAuthenticationEvents.selectedProviderIsNotImplemented, {
        provider: to.params.provider,
        status: EAuthenticationProviderSignInFlowStates.FAILED,
        signInContext: { ...to.query },
        route: to.name as string,
        route_params: { ...to.params },
        route_query: { ...to.query },
      })

      next({ name: CRouteNames.home })
    },
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
] satisfies RouteRecordRaw[]
