<template>
  <CardMenuBaseAction
    v-if="showAction"
    aria-label="Move card to the previous column"
    @click="moveCardToThePreviousColumn(cardData)"
  >
    <ChevronLeftIcon class="mr-2 h-5 w-5" />
    Move left
  </CardMenuBaseAction>
  <CardMenuBaseAction
    v-if="showAction"
    aria-label="Move card to the next column"
    @click="moveCardToTheNextColumn(cardData)"
  >
    <ChevronRightIcon class="mr-2 h-5 w-5" />
    Move right
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useCardExpandedDialogStore } from '@/domain/cardExpanded/services/useCardExpandedDialogStore'
import { CardTypeProps } from '../support/interfaces'

export const componentName = 'CardMenuActionMoveCard'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'
import { Card } from '@/types'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/outline'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'

const props = withDefaults(defineProps<{ cardData: Card; type: CardTypeProps }>(), {
  cardData: {} as Card,
})

const cardsStore = useCardsStore()
const { moveCardToTheNextColumn, moveCardToThePreviousColumn } = cardsStore
const { isReadOnly } = storeToRefs(cardsStore)

const { cardIsExpanded } = storeToRefs(useCardExpandedDialogStore())

const showAction = computed(() => {
  return !isReadOnly.value && !cardIsExpanded.value && props.type === 'child'
})
</script>

<style scoped></style>
