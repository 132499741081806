<template>
  <BaseNavigationLayout class="relative bg-base-100" :class="componentName">
    <template #left>
      <RouterLink
        v-if="true"
        class="grow-0 flex items-center justify-center"
        :to="{ name: CRouteNames.home }"
      >
        <MainNavigationLogo />
      </RouterLink>
    </template>
    <template v-if="showActionGroup" #right>
      <div class="flex gap-x-4 w-auto grow items-center justify-end leading-[150%]">
        <NotificationMenu
          class="hidden md:block text-state-error hover:text-state-error-focus"
        />
        <div class="hidden items-center gap-x-6 md:flex">
          <button
            id="new-topic"
            class="focus:base-100 rounded-xl text-state-error-content uppercase border border-state-error bg-state-error px-4 py-1 text-sm hover:bg-state-error-focus"
            @click="openCreateNewTopicDialog"
          >
            <span class="flex flex-nowrap items-center h-[24px]">
              <PlusIcon class="mr-2 h-5 w-5" />
              New guide
            </span>
          </button>
        </div>

        <button
          class="bg-state-error rounded-xl py-1.5 px-4 text-state-error-content md:hidden"
          @click="openCreateNewTopicDialog"
        >
          <PlusIcon class="h-5 w-5" />
        </button>

        <RouterLink
          v-if="isLoggedIn"
          v-slot="{ navigate }"
          :to="{ name: CRouteNames.dashboard }"
          class="hidden xs:block flex grow-0 justify-center font-medium rounded-xl px-4 py-1 bg-state-error-content text-state-error border border-state-error hover:text-state-error-focus uppercase hover:border-state-error-focus text-sm"
        >
          <button role="link" class="uppercase h-[24px]" @click="navigate">
            Dashboard
          </button>
        </RouterLink>

        <ProfileMenu :show-avatar="true" />
      </div>
    </template>
  </BaseNavigationLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProfileMenu from '@/app/components/ProfileMenu.vue'
import BaseNavigationLayout from '@/app/components/BaseNavigationLayout.vue'
import { PlusIcon } from '@heroicons/vue/outline'
import NotificationMenu from '@/domain/notifications/components/NotificationMenu.vue'
import { RouterLink } from 'vue-router'
import MainNavigationLogo from '@/app/components/MainNavigationLogo.vue'

export const componentName = 'Navigation'

export default defineComponent({
  name: componentName,
  components: {
    MainNavigationLogo,
    BaseNavigationLayout,
    // eslint-disable-next-line vue/no-unused-components
    PlusIcon,
    ProfileMenu,
    NotificationMenu,
    RouterLink,
  },
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    showActionGroup: {
      type: Boolean,
      default: true,
    },
    hasLogoNavigationAction: {
      type: Boolean,
      default: true,
    },
  },
})
</script>

<script lang="ts" setup>
import { useUserStore } from '@/app/services/useUserStore'
import { computed } from 'vue'
import { useCreateNewTopicDialogStore } from '@/domain/createTopic/services/useCreateNewTopicDialogStore'
import { storeToRefs } from 'pinia'
import { useDashboardSearchStore } from '@/app/services/useDashboardSearchStore'
import { useRouter } from 'vue-router'
import { CRouteNames } from '@/app/contracts/CRouteNames'

const { isLoggedIn } = useUserStore()

const { open: openCreateNewTopicDialog } = useCreateNewTopicDialogStore()
const { topicsSearchString } = storeToRefs(useDashboardSearchStore())

const router = useRouter()

const activateSearchAction = computed(
  () => router?.currentRoute?.value?.name === CRouteNames.dashboard,
)
</script>

<style scoped></style>
