<template>
  <div
    ref="cardRef"
    tabindex="-1"
    class="relative cursor-pointer px-5 py-2 group"
    :class="[
      isExpanded
        ? 'h-full w-full'
        : 'h-card min-w-[280px] h-xs:w-card-xs h-xs:h-card-xs rounded-lg drop-shadow-lg focus:outline-none max-w-[280px]',
      cardColors.background,
      componentName,
      isTopicCard ? 'py-5' : '',
      isProcessing && !isExpanded
        ? 'outline-dotted outline-2 outline-offset-4 outline-[#FFC901]'
        : '',
    ]"
    :aria-label="isExpanded ? 'Expanded Card' : 'Card'"
    :data-card-id="cardData.id"
    @click.stop.prevent="handleExpandCardClicked"
  >
    <div class="flex h-full flex-col justify-between" :aria-label="cardTypeAriaLabel">
      <div
        class="CardHeader flex flex-col overflow-hidden"
        :class="isExpanded ? 'mr-8' : ''"
      >
        <CardBreadcrumbs
          v-if="isExpanded"
          :current-card="cardData"
          :current-position="cardEnumeration"
        />
        <span
          v-if="isCollapsed"
          class="CardEnumeration block truncate text-base font-normal leading-6"
          :class="[cardColors.title]"
          aria-label="Card enumeration"
        >
          {{ cardEnumeration }}
        </span>
      </div>
      <div
        class="flex relative grow flex-col overflow-hidden"
        :class="[
          isExpanded ? 'mr-8' : '',
          useUAParser().isSafari() ? 'safari-redraw-fix' : '',
        ]"
      >
        <div class="flex gap-2 items-start overflow-y-scroll mr-6">
          <span
            v-if="isExpanded && cardEnumeration"
            class="CardEnumerationTitlePrefix text-xl font-semibold leading-5"
            :class="[cardColors.title]"
            aria-label="Card enumeration which prefixes the title"
          >
            {{ cardEnumeration }}
          </span>
          <InlineInput
            v-model:is-title-editing="isTitleEditing"
            class="font-semibold leading-5 placeholder:font-normal"
            :class="[
              isExpanded ? 'text-xl' : 'text-lg',
              cardColors.title,
              !isTitleEditing &&
                'select-none !cursor-pointer line-clamp-3 h-xs:line-clamp-2 text-ellipsis',
            ]"
            :value="cardTitle ?? ''"
            :max-lines="isExpanded ? 2 : 3"
            input-aria-label="Card title"
            readonly-aria-label="Card title"
            placeholder="Add your title"
            :should-double-click-to-edit="!isProcessing"
            :disable-click-handler="true"
            :focus-on-mount="computeFocusOnMount"
            :override-single-click-handler="handleExpandCardClicked"
            @inline-input-is-editing="handleActivateCardClicked"
            @inline-input-stopped-editing="handleTitleStoppedEditing"
          />
        </div>
        <button
          v-if="!isProcessing"
          class="absolute top-0 right-0 z-5"
          :class="[!useIsTouchDevice().value ? showCardActions : 'hidden']"
          @click.stop.prevent="activateEditTitle"
        >
          <PencilIcon class="h-5 w-5" :class="[cardColors.icon]" />
        </button>
      </div>
      <div class="CardFooter relative flex w-full items-center justify-start gap-x-1">
        <span
          v-if="isParentCard"
          class="absolute -z-10 bottom-0 left-0 mb-1 h-5 w-12 rounded-lg"
          aria-label="Color Marker"
          :class="cardColors.marker"
        ></span>
        <CardIsSharedForCollaborationBadge :card-data="cardData" />
        <NodeProcessBadge
          :class="[cardColors.icon, 'bg-card-state-waiting']"
          :card-data="cardData"
          :label="isGenerating ? 'Generating ...' : 'Loading ...'"
          :is-processing="isProcessing"
        />
        <div class="grow"></div>

        <MenuAlt2Icon
          v-show="true"
          :class="[!useIsTouchDevice().value && showCardActions, cardColors.icon]"
          class="mr-3 h-5 w-5"
        />
        <CardDrillDownAction
          v-if="true"
          v-show="hasChildren"
          :class="[!useIsTouchDevice().value && showCardActions, cardColors.icon]"
          :card-data="cardData"
          :card-enumeration="cardEnumeration"
        />
        <CardMenu
          class="ml-auto"
          :class="[!useIsTouchDevice().value && showCardActions, cardColors.icon]"
          :used-in-title-context="false"
          :card-data="cardData"
          :type="type"
          :is-processing="isProcessing"
          :is-expanded="props.isExpanded"
          @menu-visibility-changed="onMenuVisibilityChange"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'Card'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { computed, ref, toRaw, onMounted, watch, onUnmounted } from 'vue'
import { MenuAlt2Icon, PencilIcon } from '@heroicons/vue/outline'
import InlineInput from '@/domain/cards/components/InlineInput.vue'
import CardMenu from '@/domain/cards/components/CardMenu.vue'
import CardIsSharedForCollaborationBadge from '@/app/components/CardIsSharedForCollaborationBadge.vue'
import { storeToRefs } from 'pinia'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { useHasContent } from '@/domain/cards/services/useHasContent'
import { Card } from '@/types'
import { onKeyStroke, promiseTimeout, useTimeoutPoll } from '@vueuse/core'

import {
  getMetaDataValue,
  resolveCardStateId,
  resolveMetaDataFromCard,
  setMetaDataValue,
} from '@/domain/cards/support/helper'
import { metaDataTypes, updateMetaData } from '@/app/services/metaDataClient'
import { useCardExpandedDialogStore } from '@/domain/cardExpanded/services/useCardExpandedDialogStore'
import {
  CardStateIds,
  resolveCardState,
  resolveDefaultCardState,
} from '@/app/support/cardStates'
import NodeProcessBadge from '@/app/components/NodeProcessBadge.vue'
import { CardTypeProps } from '../support/interfaces'
import CardDrillDownAction from '@/app/components/CardDrillDownAction.vue'
import CardBreadcrumbs from '@/domain/cards/components/CardBreadcrumbs.vue'
import { useIsTouchDevice } from '@/domain/cards/support/useIsTouchDevice'
import { useProvideCardTitleEdit } from '@/domain/cards/services/useCardTitleEdit'
import { createIsNodeCopyCreationInProcess } from '@/domain/cards/services/createIsNodeCopyCreationInProcess'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { createIsDetailANodeWithAdditionalStepsInProcess } from '@/domain/cards/services/createIsDetailANodeWithAdditionalStepsInProcess'
import { getCardData, getChildrenData } from '@/domain/cards/services/cardClient'
import { useTheBlockStore } from '@/domain/blockList/services/useTheBlockStore'
import { createIsExplainANodeInProcess } from '@/domain/cards/services/createIsExplainANodeInProcess'
import { useUAParser } from '@/app/composables/useUAParser'
import { useExtendDocumentProgress } from '@/domain/cards/composables/useExtendDocumentProgress'

export interface CardProps {
  cardData: Card
  type: CardTypeProps
  isExpanded?: boolean
  usedInExpandedContext?: boolean
  cardEnumeration?: string
}

const props = withDefaults(defineProps<CardProps>(), {
  cardData: {},
  type: 'parent',
  isExpanded: false,
  usedInExpandedContext: false,
  cardEnumeration: '',
})

const menuIsOpen = ref(false)
const onMenuVisibilityChange = (event: boolean) => {
  menuIsOpen.value = event
}

const isCardCopyCreationInProcess = createIsNodeCopyCreationInProcess(props.cardData)

const showCardActions = computed(() => {
  if (isCardCopyCreationInProcess.value) {
    return 'hidden'
  }

  return menuIsOpen.value ? 'visible' : 'show-on-focus'
})

const isCollapsed = computed(() => !props.isExpanded)

const isTopicCard = computed(() => props.type === 'topic')
const isParentCard = computed(() => props.type === 'parent')
const { hasChildren } = useHasContent(props.cardData)

const emit = defineEmits(['card-clicked', 'card-keydown-enter'])

const cardRef = ref()

const { isTitleEditing, activateEditTitle } = useProvideCardTitleEdit()

const cardStore = useCardsStore()
const { activeCardId, newCreatedCardId } = storeToRefs(cardStore)
const isCardActive = computed(
  () => activeCardId?.value && activeCardId.value === props.cardData.id,
)

const { pushCardToActiveCardHistory } = cardStore
const cardTitle = computed(() => {
  const titleMetaData = resolveMetaDataFromCard(props.cardData, metaDataTypes.titleId)
  return getMetaDataValue(titleMetaData)
})

const cardColors = computed(() => {
  const cardStateId = resolveCardStateId(props.cardData)
  const resolvedCardState = resolveCardState(cardStateId) ?? resolveDefaultCardState()
  const defaultState = resolveDefaultCardState()

  if (isTopicCard.value) {
    return {
      title: resolvedCardState?.title ?? defaultState?.title,
      marker: null,
      background: resolvedCardState?.background ?? defaultState?.background,
      icon: cardStateBasedIconClasses.value,
    }
  }

  if (isParentCard.value) {
    return {
      title:
        cardStateId === CardStateIds.problem
          ? defaultState?.title
          : resolvedCardState?.title,
      marker: resolvedCardState?.marker,
      background: 'bg-base-300',
      icon: resolveDefaultCardState()?.icon,
    }
  }

  // childCard
  return {
    title: resolvedCardState?.title ?? defaultState?.title,
    marker: null,
    background: resolvedCardState?.background ?? defaultState?.background,
    icon: cardStateBasedIconClasses.value,
  }
})

const cardExpandedDialogStore = useCardExpandedDialogStore()
const { dialogIsVisible } = storeToRefs(cardExpandedDialogStore)
const isCardNewlyCreated = computed<boolean>(
  () => props.cardData.id === newCreatedCardId.value,
)
const computeFocusOnMount = computed(() => {
  const canFocus = isCardNewlyCreated.value && cardTitle.value === null
  if (props.usedInExpandedContext) {
    return canFocus
  }
  if (dialogIsVisible.value) {
    return false
  }
  return canFocus
})

const handleTitleStoppedEditing = async ({ value }) => {
  if (value !== '') {
    const titleMetaData = resolveMetaDataFromCard(props.cardData, metaDataTypes.titleId)
    const oldValue = getMetaDataValue(titleMetaData)
    setMetaDataValue(titleMetaData, value)

    try {
      await updateMetaData(titleMetaData.id, value)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('🚨', 'failed to update title metaData', error, {
        card: props.cardData,
      })
      setMetaDataValue(titleMetaData, oldValue)
    }
  }
}
const handleActivateCardClicked = () => {
  if (cardRef.value) {
    pushCardToActiveCardHistory(props.cardData.id)
  }
}

onKeyStroke('Enter', async (event) => {
  event.preventDefault()
  if (!isCardActive?.value) {
    return
  }

  // prevent topic create because there is the `Create topic` button
  if (
    props.cardData.attributes.cardType === 'topic' &&
    useCardExpandedDialogStore().cardIsExpanded === false
  ) {
    return
  }

  if (useCardExpandedDialogStore().cardIsExpanded === false) {
    const values = {
      parentId:
        event.metaKey === true
          ? props.cardData.id
          : props.cardData.relationships.parent.data.id,
      siblingId: event.metaKey === true ? undefined : props.cardData.id,
    }
    await useCardsStore().createAnEmptyNewCardForContext(
      values.parentId,
      values.siblingId,
    )
  }
})

const cardTypeAriaLabel = computed(
  () => `${props.type.charAt(0).toUpperCase()}${props.type.substring(1)} card`,
)

const cardStateBasedIconClasses = computed(() => {
  const cardState = resolveCardState(resolveCardStateId(props.cardData))
  return (cardState ?? resolveDefaultCardState())?.icon
})

const isGenerating = computed(() => {
  const extendDocumentWithAiIdMetadata = resolveMetaDataFromCard(
    props.cardData,
    metaDataTypes.extendDocumentWithAiId,
  )

  const detailANodeWithAdditionalStepsMetadata = resolveMetaDataFromCard(
    props.cardData,
    metaDataTypes.detailANodeWithAdditionalSteps,
  )

  const explainANodeMetadata = resolveMetaDataFromCard(
    props.cardData,
    metaDataTypes.explainANode,
  )

  return (
    (extendDocumentWithAiIdMetadata &&
      !['undefined'].includes(extendDocumentWithAiIdMetadata?.attributes?.status)) ||
    (detailANodeWithAdditionalStepsMetadata &&
      !['undefined'].includes(
        detailANodeWithAdditionalStepsMetadata?.attributes?.status,
      )) ||
    (explainANodeMetadata &&
      !['undefined'].includes(explainANodeMetadata?.attributes?.status))
  )
})

const { init, isGenerating: isExtendingDocument } = useExtendDocumentProgress()

const isProcessing = computed(() => {
  const isNodeCopyCreationInProcess = createIsNodeCopyCreationInProcess(props.cardData)

  if (
    props.cardData.attributes.cardType !== 'topic' &&
    isNodeCopyCreationInProcess.value
  ) {
    return false
  }

  if (props.cardData?.attributes?.cardType === 'topic') {
    return isExtendingDocument.value
  }

  return (
    isNodeCopyCreationInProcess.value ||
    createIsDetailANodeWithAdditionalStepsInProcess(props.cardData).value ||
    createIsExplainANodeInProcess(props.cardData).value
  )
})

const fetchChildrenIteration = ref(0)

const fetchChildren = async () => {
  const exponentialWaitingTime = Math.min(
    100 * Math.pow(2, fetchChildrenIteration.value),
    10000,
  )
  await promiseTimeout(exponentialWaitingTime)
  fetchChildrenIteration.value++

  const card = await getCardData(props.cardData.id)
  const isProcessing = createIsDetailANodeWithAdditionalStepsInProcess(card).value

  if (!isProcessing) {
    pauseFetchChildren()
    fetchChildrenIteration.value = 0
    const children = await getChildrenData(card)

    if (children) {
      useCardsStore().children[card.id] = children
      useCardsStore()
        .parents.find((parent) => parent.id === card.id)
        ?.relationships.children.data.push(...children)
    }

    const detailANodeWithAdditionalStepsMetadata = resolveMetaDataFromCard(
      props.cardData,
      metaDataTypes.detailANodeWithAdditionalSteps,
    )

    const newStatus = resolveMetaDataFromCard(
      card,
      metaDataTypes.detailANodeWithAdditionalSteps,
    )

    detailANodeWithAdditionalStepsMetadata.attributes.payload.status =
      newStatus.attributes.payload.status

    return
  }
}

const { pause: pauseFetchChildren, resume: resumeFetchChildren } = useTimeoutPoll(
  fetchChildren,
  1000,
)

const fetchBlockIteration = ref(0)

const fetchBlocks = async () => {
  const exponentialWaitingTime = Math.min(
    100 * Math.pow(2, fetchBlockIteration.value),
    10000,
  )
  await promiseTimeout(exponentialWaitingTime)
  fetchBlockIteration.value++

  const card = await getCardData(props.cardData.id)
  const isProcessing = createIsExplainANodeInProcess(card).value

  if (!isProcessing) {
    pauseFetchBlocks()
    fetchBlockIteration.value = 0

    await useTheBlockStore().fetchAll(card.id, true)

    const explainANodeMetadata = resolveMetaDataFromCard(
      props.cardData,
      metaDataTypes.explainANode,
    )

    const newStatus = resolveMetaDataFromCard(card, metaDataTypes.explainANode)

    explainANodeMetadata.attributes.payload.status = newStatus.attributes.payload.status

    return
  }
}

const { pause: pauseFetchBlocks, resume: resumeFetchBlocks } = useTimeoutPoll(
  fetchBlocks,
  1000,
)

watch(
  () => isProcessing.value,
  (newValue, oldValue) => {
    // figure out what type is processing
    if (
      newValue &&
      !oldValue &&
      createIsDetailANodeWithAdditionalStepsInProcess(props.cardData).value
    ) {
      resumeFetchChildren()
    }

    if (newValue && !oldValue && createIsExplainANodeInProcess(props.cardData).value) {
      resumeFetchBlocks()
    }
  },
)

const handleExpandCardClicked = () => {
  if (isProcessing.value) {
    return
  }

  emit('card-clicked', toRaw(props.cardData)) // @todo internalize the card click behavior based on external context
}

onMounted(async () => {
  if (!cardTitle.value && props.type === 'topic' && !props.isExpanded) {
    // reload only documents when there is no title
    await useTopicsStore().refreshTopics()
  }

  init(props.cardData.id)
})

watch(
  () => props.cardData,
  (newDocument) => {
    init(newDocument.id)
  },
)

onUnmounted(() => {
  pauseFetchChildren()
  pauseFetchBlocks()
})
</script>

<style scoped>
.show-on-focus {
  @apply invisible group-focus-within:visible group-hover:visible group-focus-visible:visible;
}
</style>
