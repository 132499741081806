<template>
  <CardMenuBaseAction
    v-if="showAction"
    id="delete"
    aria-label="Delete card"
    @click="deleteCard(props.cardData, replaceUrl)"
  >
    <TrashIcon class="mr-2 h-5 w-5" />
    Delete
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuActionDelete'

export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'
import { TrashIcon } from '@heroicons/vue/outline'
import { Card } from '@/types'
import { NavigationFailure, RouteLocationRaw, useRouter } from 'vue-router'
import { computed, Ref, toRaw } from 'vue'
import { CardTypeProps } from '@/domain/cards/support/interfaces'
import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { storeToRefs } from 'pinia'
import { useCardExpandedDialogStore } from '@/domain/cardExpanded/services/useCardExpandedDialogStore'
import { CRouteNames } from '@/app/contracts/CRouteNames'

const props = withDefaults(defineProps<{ cardData: Card }>(), {
  cardData: {} as Card,
})

const { replace: replaceUrl } = useRouter()

const sendRestoreNotification = (card: Card, parents: Ref<Card[]>) => {
  const parent = parents.value.find((data) => data.id === card.id)
  const type: CardTypeProps = parent
    ? 'parent'
    : card.attributes.cardType === 'topic'
    ? 'topic'
    : 'child'

  useRestoreHistory().setUndoNotification({
    type,
    id: card.id,
    value: toRaw(card),
  })
}

const closeExpandedDialog = () => {
  const cardExpandedDialogStore = useCardExpandedDialogStore()
  const { expandedCard } = storeToRefs(cardExpandedDialogStore)
  if (props.cardData.id !== expandedCard.value.id) {
    return
  }
  cardExpandedDialogStore.close()
}

const deleteCard = async (
  card: Card,
  replace: (to: RouteLocationRaw) => Promise<NavigationFailure | void | undefined>,
) => {
  const { removeTopic } = useTopicsStore()
  const cardStore = useCardsStore()
  const { deleteChildrenCard, deleteParentCard, popCardFromActiveCardHistory } =
    useCardsStore()
  const { parents } = storeToRefs(cardStore)

  sendRestoreNotification(card, parents)
  popCardFromActiveCardHistory()

  // case delete topic
  if (card?.attributes?.cardType === 'topic') {
    await removeTopic(card?.id)
    closeExpandedDialog()
    await replace({ name: CRouteNames.dashboard })
    return
  }

  // case delete parent
  const parentId = card?.relationships?.parent?.data?.id
  const topicId = card?.relationships?.topic?.data?.id
  if (parentId === topicId) {
    await deleteParentCard(card?.id)
    closeExpandedDialog()
    return
  }

  // case delete child
  await deleteChildrenCard(parentId, card)
  closeExpandedDialog()
}

const { isReadOnly } = storeToRefs(useCardsStore())
const showAction = computed(() => {
  return !isReadOnly.value
})
</script>
