import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'
import PContent from '@/domain/Content/components/PContent.vue'
import PCase from '@/domain/Content/components/PCase.vue'
import type { RouteRecordRaw } from 'vue-router'
import PContentDemo from '@/domain/Content/components/PContentDemo.vue'
import { Environment } from '@/app/support/environment'
import { CRouteNames } from '@/app/contracts/CRouteNames'

export const contentRoutes: RouteRecordRaw[] = [
  {
    path: '/:slug',
    name: CContentRouteNames.default,
    component: PContent,
  },
  {
    path: '/content/demo',
    name: 'page.content.demo',
    component: PContentDemo,
    beforeEnter: async (to, from, next) => {
      if (Environment.isProduction()) {
        next({ name: CRouteNames.home, replace: true })
        return
      }
      next()
    },
  },
  {
    path: '/cases/:slug',
    name: CContentRouteNames.cases,
    component: PCase,
  },
  {
    path: '/books/:slug',
    name: CContentRouteNames.books,
    component: PCase,
  },
]
