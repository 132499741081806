<template>
  <CardMenuBaseAction
    v-if="isVisible"
    id="edit-title"
    :aria-label="actionTitle"
    :is-disabled="isDisabled"
    @click="explainANodeAction(props.cardData, actionTypes.explainANode)"
  >
    <LightningBoltIcon class="h-5 w-5" :class="isDisabled ? ['text-base-400'] : []" />
    <span :class="isDisabled ? ['text-base-400'] : []">{{ actionTitle }}</span>
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuActionExplain'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'
import { Card } from '@/types'
import { LightningBoltIcon } from '@heroicons/vue/outline'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useTheBlockStore } from '@/domain/blockList/services/useTheBlockStore'
import { useAiActions } from '@/domain/cards/composables/useAiActions'

const props = withDefaults(defineProps<{ cardData: Card }>(), {
  cardData: {} as Card,
})

const actionTitle = 'Explain'

const { currentRoute } = useRouter()
const isVisible = computed(() => currentRoute.value.name !== CRouteNames.dashboard)
const isDisabled = computed(() => useTheBlockStore().sortedBlocks.value.length > 0)

const { execute: explainANodeAction, actionTypes } = useAiActions()
</script>
