<template>
  <form
    class="flex flex-col space-y-4 whitespace-nowrap"
    :class="[componentName]"
    @submit.prevent="onRequestLogin"
  >
    <div class="flex space-y-2 flex-col items-start">
      <input
        id="login-email"
        ref="emailInputRef"
        v-model="email"
        type="email"
        name="email"
        placeholder="Email: you@example.com"
        class="flex w-full h-full flex-1 rounded-sm border border-base-300 px-3 text-sm placeholder-base-300 shadow-sm focus:border-state-error focus:ring-0"
        :class="[
          !isFresh && errorMessage?.length ? 'border-state-error' : 'border-gray-300',
        ]"
        :disabled="isRequestRunning"
        @invalid.prevent="() => ({})"
      />
    </div>
    <button
      id="submit-email"
      ref="submitButton"
      type="submit"
      aria-label="Submit user email"
      class="mt-6 flex h-full items-center justify-center rounded bg-state-error px-3.5 py-2 text-sm font-medium text-base-100 shadow-sm hover:bg-state-error-focus"
      :class="[
        hasRequestFailed
          ? 'border text-state-error bg-state-error-content border-state-error hover:text-state-error-content hover:border-state-error-focus hover:bg-state-error-content'
          : undefined,
        isRequestRunning ? 'cursor-progress opacity-50' : undefined,
        (isFresh && isEmailValid) || isEmailValid
          ? undefined
          : 'opacity-30 cursor-not-allowed',
      ]"
    >
      <span :class="[isRequestRunning ? 'invisible' : 'visible']">
        {{ computedSubmitButtonText }}
      </span>
      <span class="absolute block" :class="isRequestRunning ? 'visible' : 'invisible'">
        <span
          class="block h-5 w-5 animate-spin rounded-full border-2 border-solid border-white/70 border-t-transparent"
        />
      </span>
    </button>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'SignInWithMagicUriForm'

export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import isEmail from 'validator/lib/isEmail'

import { useTippy } from 'vue-tippy'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { EMagicSignInStages } from '@/domain/Authentication/contracts/EMagicSignInStages'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'

const emailInputRef = ref()
const isFresh = ref<boolean>(true)
const errorMessage = ref<string>('')
const email = ref<string>('')
const isRequestRunning = computed(
  () =>
    useAuthentication().selectedProvider.value ===
      EAuthenticationProviders.MAGIC_SIGN_IN &&
    useAuthentication().status.value === EAuthenticationProviderSignInFlowStates.STARTED,
)
const hasRequestFailed = computed(
  () =>
    useAuthentication().selectedProvider.value ===
      EAuthenticationProviders.MAGIC_SIGN_IN &&
    useAuthentication().status.value === EAuthenticationProviderSignInFlowStates.FAILED,
)

const computedSubmitButtonText = computed<string>(() =>
  hasRequestFailed.value ? 'Retry' : 'Sign in with email',
)

const validateEmail = (email: string | undefined) => {
  if (!email || !email.length) {
    return false
  }

  if (!email || !isEmail(email)) {
    return false
  }

  errorMessage.value = ''
  return true
}

const isEmailValid = ref<boolean>(true)

watch(email, (newEmailValue, oldEmailValue) => {
  if (newEmailValue && newEmailValue?.length > 0 && oldEmailValue?.length === 0) {
    isFresh.value = false
  }

  if (newEmailValue?.length !== oldEmailValue?.length) {
    isEmailValid.value = validateEmail(newEmailValue)
  }
})

watch(isEmailValid, (newEmailValue) => {
  if (newEmailValue) {
    errorMessage.value = ''
    hideTippy()
    return
  }

  if (!email.value?.length) {
    errorMessage.value = 'Email is required'
  }

  if (!email.value || !isEmail(email.value)) {
    errorMessage.value = 'Please enter a valid email address.'
  }
})

const {
  show: showTippy,
  hide: hideTippy,
  setContent: setTippyContent,
} = useTippy(emailInputRef, {
  theme: 'signIn',
  trigger: 'manual',
  hideOnClick: false,
})

const onRequestLogin = async () => {
  if (!validateEmail(email.value)) {
    isEmailValid.value = false
    return
  }

  hideTippy()
  await useAuthentication().signInWith(EAuthenticationProviders.MAGIC_SIGN_IN, {
    stage: EMagicSignInStages.REQUEST_SIGN_IN_URI,
    secret: email.value as string,
  })

  await nextTick(() => {
    email.value = ''
  })
}

watch(errorMessage, (newErrorMessage) => {
  if (!newErrorMessage) {
    return
  }

  setTippyContent(newErrorMessage)
  if (newErrorMessage.length === 0) {
    hideTippy()
  } else {
    showTippy()
  }
})
</script>
<style>
.tippy-box[data-theme~='signIn'] {
  @apply relative rounded-md border border-state-error text-state-error;
  box-shadow:
    0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: #fff;
}

.tippy-box[data-theme~='signIn'][data-placement^='top'] > .tippy-arrow:before {
  @apply border-t-state-error;
}

.tippy-box[data-theme~='signIn'] .tippy-arrow {
  @apply -z-10;
}

.tippy-box[data-theme~='signIn'][data-placement^='bottom'] > .tippy-arrow:before {
  @apply border-b-state-error;
}

.tippy-box[data-theme~='signIn'][data-placement^='left'] > .tippy-arrow:before {
  @apply border-l-state-error;
}

.tippy-box[data-theme~='signIn'][data-placement^='right'] > .tippy-arrow:before {
  @apply border-r-state-error;
}

.tippy-box[data-theme~='signIn'] > .tippy-backdrop {
  @apply bg-state-error-content;
}

.tippy-box[data-theme~='signIn'] > .tippy-svg-arrow {
  fill: #ff364f;
}
</style>
