import { onMounted, onUnmounted, ref, Ref } from 'vue'
import { HTMLElement } from 'happy-dom'
import { debounce } from 'lodash-es'

const setupDom = (
  scrollableContainer: string,
  rootHtmlItemsSelector: string[] = ['html', 'body'],
) => {
  document.documentElement.style.setProperty('--vhPixelRatio', '1vh')
  document.documentElement.style.setProperty(
    '--mainNavHeight',
    'var(--navigation-height)',
  )

  rootHtmlItemsSelector.forEach((selector) => {
    const element = document.querySelectorAll(selector)[0] as any as
      | HTMLElement
      | undefined
    if (element) {
      element.classList.add('sticky', 'overflow-hidden', 'overscroll-none')
      element.style.setProperty('height', 'calc(var(--vhPixelRatio, 1vh) * 100)')
    }
  })

  const element = document.querySelectorAll(scrollableContainer)[0] as any as
    | HTMLElement
    | undefined

  if (element) {
    element.style.setProperty(
      'height',
      'calc(var(--vhPixelRatio, 1vh) * 100 - var(--mainNavHeight, 52px))',
    )
    element.classList.add(
      'overflow-scroll',
      'scrollable-y',
      'transition-size',
      'duration-100',
    )
  }
}

const tearDownDomModifications = (
  scrollableContainer: string,
  rootHtmlItemsSelector: string[] = ['html', 'body'],
) => {
  document.documentElement.style.removeProperty('--vhPixelRatio')
  document.documentElement.style.removeProperty('--mainNavHeight')

  rootHtmlItemsSelector.forEach((selector) => {
    const element = document.querySelectorAll(selector)[0] as any as
      | HTMLElement
      | undefined
    if (element) {
      element.classList.remove('sticky', 'overflow-hidden', 'overscroll-none')
      element.style.removeProperty('height')
    }
  })

  const element = document.querySelectorAll(scrollableContainer)[0] as any as
    | HTMLElement
    | undefined

  if (element) {
    element.style.removeProperty('height')
    element.classList.remove(
      'overflow-scroll',
      'scrollable-y',
      'transition-size',
      'duration-100',
    )
  }
}

const fixAndroidBlur = (vh: number, lastVH: number) => {
  // (Android) fix blurring inputs when keyboard is being closed (e.g. closing keyboard by back arrow and touching a bubble)
  if (lastVH < vh && vh - lastVH > 1) {
    if ((document.activeElement as HTMLInputElement)?.blur) {
      ;(document.activeElement as HTMLInputElement).blur()
      return true
    }
  }

  return false
}

export const useScrollableContainer = (
  scrollableContainer: string,
  rootHtmlItemsSelector: string[],
  useVisualViewPortForViewHeight: () => boolean,
) => {
  const viewRef = ref<Window | VisualViewport | null>(null)
  const lastViewHeightFraction: Ref<number> = ref(0)
  const shouldUseVisualViewPortRef: Ref<boolean> = ref(useVisualViewPortForViewHeight())

  const normalizeViewHeight = () => {
    let currentViewHeightFraction = window.innerHeight * 0.01

    if (shouldUseVisualViewPortRef.value) {
      currentViewHeightFraction =
        ((viewRef.value as VisualViewport).height ||
          (viewRef.value as Window).innerHeight) * 0.01
    }

    currentViewHeightFraction = +currentViewHeightFraction.toFixed(2)

    if (lastViewHeightFraction.value === currentViewHeightFraction) {
      return
    }

    fixAndroidBlur(currentViewHeightFraction, lastViewHeightFraction.value)

    lastViewHeightFraction.value = currentViewHeightFraction
    document.documentElement.style.setProperty(
      '--vhPixelRatio',
      `${currentViewHeightFraction}px`,
    )
    // @todo with an focues input & rotating the device 360° the vh is resolved
    //  uncorrectly leading to scrollable vh < the visual space when
    //  virtual keyboard is visible
  }

  const debouncedNormalizeViewHeight = debounce(normalizeViewHeight, 0, {
    trailing: true,
    maxWait: 1000,
  })

  onMounted(() => {
    setupDom(scrollableContainer, rootHtmlItemsSelector)

    viewRef.value = window.visualViewport || window
    viewRef.value.addEventListener('resize', normalizeViewHeight) // ios
    debouncedNormalizeViewHeight()
  })

  onUnmounted(() => {
    tearDownDomModifications(scrollableContainer, rootHtmlItemsSelector)
    if (viewRef.value !== null) {
      viewRef.value.removeEventListener('resize', normalizeViewHeight)
    }
  })
}
