import { useUserStore } from '@/app/services/useUserStore'
import { Environment } from '@/app/support/environment'
import { useConfig } from '@/app/services/useConfig'

const userStore = useUserStore()
const { email } = userStore

export const yBugSettingsFactory = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (window.ybug_settings = {
      id: useConfig().get().yBug.key,
      hide_launcher: true,
      user: {
        email: email,
        environment: Environment.getName(),
      },
      settings: {
        feedback: {
          email: email,
        },
      },
    })
  )
}
