import { onMounted, onUnmounted } from 'vue'

const addCssClasses = (element: Element, cssClasses: string[]) => {
  element.classList.add(...cssClasses)
}

const removeCssClasses = (element: Element, cssClasses: string[]) => {
  element.classList.remove(...cssClasses)
}

export const useDirectCssClassOverride = (selectors: string[], cssClasses: string[]) => {
  onMounted(() => {
    selectors.forEach((selector) => {
      document
        .querySelectorAll(selector)
        .forEach((element: Element) => addCssClasses(element, cssClasses))
    })
  })

  onUnmounted(() => {
    selectors.forEach((selector) => {
      document
        .querySelectorAll(selector)
        .forEach((element: Element) => removeCssClasses(element, cssClasses))
    })
  })
}
