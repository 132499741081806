<template>
  <MenuItem v-slot="{ close, active }" as="template">
    <button
      class="flex w-full items-center gap-2 px-4 py-2 font-normal text-base-content hover:bg-base-200 focus:outline-none focus:ring-0"
      :class="[
        ...(active ? ['bg-base-300', 'text-base-500'] : []),
        ...(isDisabled ? ['cursor-not-allowed'] : []),
      ]"
      :disabled="props.isDisabled"
      @click=";[onClick(), close()]"
    >
      <slot />
    </button>
  </MenuItem>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuActionBase'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { MenuItem } from '@headlessui/vue'
import { defineOptions, useAttrs } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const attrs = useAttrs()
const onClick = attrs['onClick'] as () => void
const props = withDefaults(defineProps<{ isDisabled?: boolean }>(), {
  isDisabled: undefined,
})
</script>
