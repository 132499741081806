import { computed } from 'vue'

export const useHasContent = (card: any) => {
  const hasChildren = computed(() => card.relationships?.children?.data?.length > 0)
  const hasBlocks = computed(
    () =>
      card.relationships?.blocks?.data?.length > 0 && card.attributes.blocksHasContent,
  )
  const hasContent = computed(() => hasBlocks.value)

  return {
    hasContent,
    hasChildren,
  }
}
