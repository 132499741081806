import { ref } from 'vue'
import { createInjectionState } from '@vueuse/shared'
import { injectionStateFactory } from '@/app/support/injectionStateFactory'

const [useProvideCardTitleEdit, useCardTitleEditState] = createInjectionState(
  (initialValue?: boolean) => {
    const isTitleEditing = ref(initialValue ?? false)

    const activateEditTitle = () => {
      isTitleEditing.value = true
    }

    return { isTitleEditing, activateEditTitle }
  },
)

export { useProvideCardTitleEdit }

export const useCardTitleEdit = injectionStateFactory(useCardTitleEditState, {
  providerName: 'useProvideCardTitleEdit',
  defaults: {
    activateEditTitle: () => null,
    isTitleEditing: ref(false),
  },
})
