import { computed } from 'vue'
import { resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from '@/app/services/metaDataClient'
import { Card } from '@/types'
import { ComputedRef } from 'vue'

export const createIsNodeCopyCreationInProcess = (
  cardData: Card,
): ComputedRef<boolean> => {
  return computed(() => {
    const sharedAsCopyMeta = resolveMetaDataFromCard(
      cardData,
      metaDataTypes.sharedAsCopyId,
    )

    if (!sharedAsCopyMeta) {
      return false
    }

    return (
      cardData?.attributes?.cardType === 'topic' &&
      sharedAsCopyMeta?.attributes.payload.copyProcess &&
      sharedAsCopyMeta?.attributes.payload.copyProcess !== 'done'
    )
  })
}
