<template>
  <span aria-label="Drilldown Icon">
    <ViewGridIcon
      v-if="isVisible"
      class="mr-2 h-5 w-5"
      @click.stop.prevent="doDrillDown"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardDrillDownAction'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { Card } from '@/types'
import { ViewGridIcon } from '@heroicons/vue/outline'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { useUserLocationStore } from '@/app/services/useUserLocationStore'
import { useCardExpandedDialogStore } from '@/domain/cardExpanded/services/useCardExpandedDialogStore'
import { storeToRefs } from 'pinia'

const props = withDefaults(defineProps<{ cardData: Card; cardEnumeration: string }>(), {
  cardData: () => ({}),
  cardEnumeration: () => '',
})

const isVisible = computed(() => props.cardData?.attributes?.cardType !== 'topic')

const doDrillDown = async () => {
  const cardExpandedDialogStore = useCardExpandedDialogStore()
  const { dialogIsVisible } = storeToRefs(cardExpandedDialogStore)

  if (dialogIsVisible.value) {
    cardExpandedDialogStore.close()
  }

  await useCardsStore().fetchChildrenOf(props.cardData)
  await useUserLocationStore().navigateToContext(props.cardData.id)
}
</script>
