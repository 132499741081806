import { useUserStore } from '@/app/services/useUserStore'
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
  type RouteRecordRedirectOption,
} from 'vue-router'
import * as Fathom from 'fathom-client'

import PLanding from '@/domain/landingPage/components/PLanding.vue'

import { Environment } from '@/app/support/environment'
import { blockListRoutes } from '@/domain/blockList/blockListRoutes'
import { authenticationRoutes, CAuthenticationRouteNames } from '@/domain/Authentication'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import PDashboard from '@/domain/dashboard/components/PDashboard.vue'
import { shareTopicNavigationGuard } from '@/app/services/navigation-guards/shareTopicNavigationGuard'
import { routeLogger } from '@/app/services/routeLogger'
import { nextTick, type Ref, ref } from 'vue'
import { domainsRoutes } from '@/domain/domains/domainsRoutes'
import { directoryRoutes } from '@/domain/directory/directory-routes'
import { createDocument } from '@/domain/documents/services/documentClient'
import { addRoutes } from '@/app/support/addRoutes'
import { contentRoutes } from '@/domain/Content'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: CRouteNames.home,
    component: PLanding,
  },
  {
    path: '/dashboard',
    name: CRouteNames.dashboard,
    component: PDashboard,
    beforeEnter: async (to, from, next) => {
      await useUserStore().refresh()
      if (!useUserStore().isLoggedIn.value) {
        captureWithUser(EUserEvents.visited, {
          route: to.name,
          route_query: { ...to.query },
          route_params: { ...to.params },
          guard: `${CRouteNames.dashboard}.beforeEnter`,
          comment: `Authentication required to access dashboard redirect to '${CAuthenticationRouteNames.signIn}'`,
        })

        next({ name: CAuthenticationRouteNames.signIn })
        return
      }
      next()
    },
  },
  {
    path: '/items-branch-state',
    component: () => import('@/domain/ItemsBranchState/components/ItemsBranchState.vue'),
    beforeEnter: async (to, from, next) => {
      if (Environment.isProduction()) {
        next({ name: CRouteNames.home, replace: true })
        return
      }
      next()
    },
  },
  {
    path: '/topic/:topicId?',
    name: CRouteNames.topic,
    component: () => import('@/domain/cards/components/PTopic.vue'),
    children: [
      {
        path: 'c/:contextId',
        name: CRouteNames.topicWithContext,
        component: () => import('@/domain/cards/components/PTopic.vue'),
      },
    ],
  },
  {
    path: '/documents/create',
    name: CRouteNames.documents.create,
    beforeEnter: async (to, from, next) => {
      await useUserStore().refresh()
      if (!useUserStore().isLoggedIn.value) {
        const target = { name: CAuthenticationRouteNames.signIn, query: to.query }
        next(target)
        return
      }

      const prompt = to.query.prompt

      if (!prompt) {
        next({ name: CRouteNames.home, replace: true })
        return
      }

      const result = await createDocument(prompt as string)

      if (!result?.id) {
        // eslint-disable-next-line no-console
        console.error(`creating document failed for '${prompt}'`)
        next({ name: CRouteNames.home, replace: true })
        return
      }

      next({
        name: CRouteNames.topic,
        params: { topicId: result.id },
        query: to.query,
        replace: true,
      })
      return
    },
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
  {
    path: '/share-links/:shareId/apply',
    name: CRouteNames.shareTopic,
    beforeEnter: shareTopicNavigationGuard,
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
] satisfies RouteRecordRaw[]

addRoutes(routes, blockListRoutes)
addRoutes(routes, domainsRoutes)
addRoutes(routes, directoryRoutes)
addRoutes(routes, authenticationRoutes)
addRoutes(routes, contentRoutes)

export { routes }

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  Fathom.trackPageview() // track all page visits with fathom
  if (to.matched.length === 0) {
    captureWithUser(EUserEvents.visited, {
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
      guard: 'router.beforeEach',
      comment: `Route not found redirect to '${CRouteNames.home}'`,
    })

    routeLogger({
      msg: `route not found & redirect to ${CRouteNames.home}`,
      nav: { from, to },
    })
    return { name: CRouteNames.home }
  }
})

const scrollInto = (
  toHash: string,
  scrollTrials: Ref<number> = ref(0),
  maxTrials = 3,
) => {
  if (scrollTrials.value > maxTrials) {
    // eslint-disable-next-line no-console
    console.log('❌ max scroll trials reached', {
      toHash,
      scrollTrials: scrollTrials.value,
      maxTrials,
    })
    return
  }

  const childElement = document.querySelector(toHash)

  if (childElement) {
    childElement.scrollIntoView({ behavior: 'smooth' })
    scrollTrials.value = 0
  } else {
    setTimeout(() => {
      scrollTrials.value = scrollTrials.value + 1
      scrollInto(toHash, scrollTrials)
    }, 250)
  }
}

router.afterEach(async (to, from) => {
  captureWithUser(EUserEvents.visited, {
    route: to.name,
    route_query: { ...to.query },
    route_params: { ...to.params },
  })

  nextTick(() => {
    if (!document) {
      return
    }

    if (!to.hash) {
      return
    }

    const scrollTrials = ref(0)
    scrollInto(to.hash, scrollTrials)
  })
})

export default router
