<template>
  <SectionLayout
    id-attribute="discover-guides"
    width="narrow"
    :visualize-width="false"
    tag="section"
    class="mt-10 relative z-1 min-h-screen"
  >
    <div
      class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-between items-start sm:items-center w-full pb-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-x-4 sm:gap-y-8"
    >
      <h3
        class="text-left shrink-0 font-extrabold text-base-content text-3xl lg:col-span-2 xl:col-span-3"
      >
        Discover Guides
      </h3>
      <MultiSelect
        v-if="enableCategorySelector"
        v-model="selectedCategoryIds"
        :options="categories"
        filter
        option-label="label"
        option-value="id"
        placeholder="Select Category"
        :max-selected-labels="3"
        class="w-full bg-base-100"
      />
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8"
    >
      <DirectoryCard v-for="entry in entries" :key="entry.id" v-bind="entry" class="" />
    </div>
  </SectionLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'DirectorySection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import MultiSelect from 'primevue/multiselect'
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import DirectoryCard from '@/domain/directory/components/DirectoryCard.vue'
import { useDirectoryEntries } from '@/domain/directory/composables/useDirectoryEntries'
import { useConfig } from '@/app/services/useConfig'
import { onMounted, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    selectedExpertIds?: string[]
    useFakeData: boolean
    enableCategorySelector?: boolean
  }>(),
  {
    selectedExpertIds: () => [],
    useFakeData: false,
    enableCategorySelector: true,
  },
)

const { entries, categories, selectedCategoryIds, selectedExpertIds } =
  useDirectoryEntries(useConfig().get().builderIO.publicApiKey, props.useFakeData)

onMounted(() => {
  selectedExpertIds.value = props.selectedExpertIds
})

watch(
  () => props.selectedExpertIds,
  () => {
    selectedExpertIds.value = props.selectedExpertIds
  },
)
</script>

<style scoped></style>
