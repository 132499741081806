<template>
  <div
    ref="notificationRef"
    aria-live="assertive"
    aria-label="Notification"
    class="pointer-events-none inset-0 flex items-end py-1 sm:items-start"
  >
    <div class="flex w-full flex-col items-center sm:items-end">
      <div
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-xl border border-base-200 bg-base-100 px-3 py-2"
      >
        <div class="px-2 py-4">
          <div class="flex items-start gap-2">
            <div class="flex-shrink-0">
              <SunIcon class="h-6 w-6 text-accent" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1">
              <p
                class="max-w-[30ch] text-sm text-base-content"
                v-html="notification.message"
              />
              <button
                v-if="hasAction"
                type="button"
                class="mt-3 rounded-xl bg-state-error px-5 py-2 text-sm text-state-error-content shadow-sm hover:bg-state-error-focus focus:outline-none"
                aria-label="Action Button"
                @click="notification.action?.doAct"
              >
                {{ notification.action?.text }}
              </button>
            </div>
            <div class="flex flex-shrink-0">
              <button
                type="button"
                class="inline-flex text-base-300 hover:text-base-content focus:outline-none"
                aria-label="Close Notification"
                @click="close(notification)"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { SunIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import { NotificationProps } from '@/domain/notifications/services/useNotificationsStore'
import { useNotificationRefsStore } from '@/domain/notifications/services/useNotificationRefsStore'

export const componentName = 'Notification'
export default defineComponent({
  name: componentName,
  components: {
    SunIcon,
    XIcon,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationProps>,
      default: () => ({}),
    },
  },
  emits: ['closed'],
  setup(props, { emit }) {
    const notificationRef = ref(null)
    const hasAction = computed(
      () => props.notification?.action?.text && props.notification?.action?.doAct,
    )

    const { storeRef } = useNotificationRefsStore()
    onMounted(() => {
      storeRef('notificationRef', notificationRef)
    })

    return {
      close: (notification: NotificationProps) => {
        emit('closed', notification)
      },
      hasAction,
      resolvedNotification: props.notification as NotificationProps,
      notificationRef,
    }
  },
})
</script>
