import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { defineStore } from 'pinia'
import { Card } from '@/types'
import {
  createTopic,
  deleteCardById as deleteTopicById,
  getUserTopics,
  restoreCardById,
} from '@/domain/cards/services/cardClient'

import { log } from '@/app/services/errorService'
import { orderBy } from 'lodash-es'
import { resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from './metaDataClient'
import { useAsyncTopicCopyJobUpdater } from './useAsyncTopicCopyJobUpdater'
import { usePosthog } from '@/app/support/usePosthog'
import { EGuideEvents } from '@/app/contracts/EGuideEvents'

type TopicStoreState = {
  topics: Card[]
  isCreatingState: boolean
  sortBy: SortOptions
  order: 'asc' | 'desc'
}

export enum SortOptions {
  RecentlyCreated = 'Date created',
  RecentlyModified = 'Date modified',
  Alphabetical = 'Alphabetical',
}

export const useTopicsStore = defineStore('useTopicsStore', {
  state: () =>
    ({
      topics: [],
      isCreatingState: false,
      sortBy: SortOptions.RecentlyCreated,
      order: 'desc',
    }) as TopicStoreState,
  getters: {
    isCreating(state) {
      return state.isCreatingState
    },
    sortedTopics(state) {
      const sortTopics = (topic) => {
        if (state.sortBy === SortOptions.Alphabetical) {
          return resolveMetaDataFromCard(
            topic,
            metaDataTypes.titleId,
          )?.attributes?.payload?.value.toLowerCase()
        } else if (state.sortBy === SortOptions.RecentlyCreated) {
          return new Date(topic?.attributes?.createdAt)
        } else if (state.sortBy === SortOptions.RecentlyModified) {
          return new Date(
            resolveMetaDataFromCard(topic, metaDataTypes.titleId)?.attributes?.changed,
          )
        }
      }

      return orderBy(state.topics, [sortTopics], [state.order])
    },
  },
  actions: {
    async refreshTopics() {
      await this.findAllTopics()
    },
    async findAllTopics() {
      try {
        this.topics = (await getUserTopics())?.data
        useAsyncTopicCopyJobUpdater().watchJobs()
      } catch (error) {
        log(error)
      }
    },
    async addNewTopic(
      topicTitle,
      performNavigation: (topicId?: string) => Promise<void>,
      enableAiSupport = false,
    ) {
      try {
        this.isCreatingState = true

        const createdTopic = (await createTopic(topicTitle, enableAiSupport))?.data
        usePosthog().capture(EGuideEvents.created, {
          guide_id: createdTopic.id,
          guide_title: topicTitle,
          guide_ai_support_enabled: enableAiSupport,
        })

        this.isCreatingState = false
        this.topics.push(createdTopic)

        if (performNavigation) {
          performNavigation(createdTopic.id)
        }
      } catch (error) {
        log(error)
      }
    },
    async removeTopic(topicId) {
      await this.findTopicsIfEmpty()
      const topicIndex = this.topics.findIndex((t) => t.id === topicId)
      if (topicIndex < 0) {
        // eslint-disable-next-line no-console
        log('Could not find topic by id', { topicId })
        return
      }
      this.topics.splice(topicIndex, 1)

      try {
        await deleteTopicById(topicId)
      } catch (error) {
        log(error)
      }
    },
    async restoreLastTopic() {
      const { lastDeleted } = useRestoreHistory()
      const { id } = lastDeleted.value
      await restoreCardById(id)
      await this.findAllTopics()
    },
    async findTopicsIfEmpty() {
      if (this.topics.length) {
        return
      }
      await this.findAllTopics()
    },
  },
})
