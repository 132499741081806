<template>
  <ul
    v-if="breadcrumbs.length"
    class="mb-2 flex flex-wrap items-center gap-x-3 gap-y-2"
    aria-label="Breadcrumbs"
  >
    <li v-for="(item, index) in breadcrumbs" :key="index" class="flex items-center gap-3">
      <span
        class="max-w-[16rem] cursor-pointer truncate hover:underline"
        :class="[!dialogIsVisible ? 'text-base-300' : 'text-base-400']"
        :title="breadcrumbTitle(item)"
        @click="breadcrumbNavigation(item, computeTitleIndex(index))"
      >
        <span v-if="item?.attributes?.cardType !== 'topic'">
          {{ computeTitleIndex(index) }}.
        </span>
        {{ breadcrumbTitle(item) }}
      </span>
      <ArrowRightIcon
        class="h-4 w-4"
        :class="[!dialogIsVisible ? 'text-base-300' : 'text-base-400']"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { resolveMetaDataFromCard } from '../support/helper'
import { ArrowRightIcon } from '@heroicons/vue/outline'
import { metaDataTypes } from '@/app/services/metaDataClient'
import { useCardExpandedDialogStore } from '@/domain/cardExpanded/services/useCardExpandedDialogStore'
import { storeToRefs } from 'pinia'
import { useCardsStore } from '../services/useCardsStore'
import { useUserLocationStore } from '@/app/services/useUserLocationStore'

export const componentName = 'CardBreadcrumbs'
export default defineComponent({
  name: componentName,
  components: {
    ArrowRightIcon,
  },
  props: {
    currentPosition: {
      type: String,
      default: '1',
    },
    currentCard: {
      type: Object,
      default: () => ({}),
    },
  },
  setup: (props) => {
    const breadcrumbTitle = computed(() => {
      return (cardData) =>
        resolveMetaDataFromCard(cardData, metaDataTypes.titleId)?.attributes.payload.value
    })

    const cardExpandedDialogStore = useCardExpandedDialogStore()
    const { pushNewExpandedRoute: expandCardWithDialog } = cardExpandedDialogStore
    const { dialogIsVisible } = storeToRefs(cardExpandedDialogStore)
    const cardsStore = useCardsStore()
    const { parents, children, topic } = storeToRefs(cardsStore)
    const { getCardParent } = cardsStore
    const { navigateToContext, viewId } = useUserLocationStore()

    const breadcrumbNavigation = (card, index: string) => {
      const relationships = card?.relationships
      const topicId = relationships?.topic?.data?.id
      const parentId = relationships?.parent?.data?.id
      if (viewId.value.length && !dialogIsVisible.value) {
        navigateToContext(card.id)
        return
      }
      expandCardWithDialog(card.id)
    }

    const getBreadCrumbs = (breadcrumbCards: string[], card) => {
      const relationships = card?.relationships
      const topicId = relationships?.topic?.data?.id
      const parentId = relationships?.parent?.data?.id
      if (!topicId) {
        return
      }
      if (topicId === parentId) {
        breadcrumbCards.unshift(topic.value)
        return
      }
      const parent = getCardParent(parentId)
      breadcrumbCards.unshift(parent)
      getBreadCrumbs(breadcrumbCards, parent)
    }

    const breadcrumbs = computed(() => {
      const breadCrumbs: any[] = []
      getBreadCrumbs(breadCrumbs, props.currentCard)
      return breadCrumbs
    })

    const computeTitleIndex = computed(() => {
      return (index) => props.currentPosition.split('.').slice(0, index).join('.')
    })

    return {
      breadcrumbTitle,
      breadcrumbNavigation,
      computeTitleIndex,
      breadcrumbs,
      dialogIsVisible,
    }
  },
})
</script>
