<template>
  <SectionLayout
    v-if="url"
    :id="sectionId"
    width="narrow"
    class="shrink-0"
    :class="[containerClasses, componentName]"
    :visualize-width="false"
    tag="section"
  >
    <div class="mb-12">
      <h1
        class="text-3xl lg:text-4xl font-extrabold tracking-tight text-base-content max-w-2xl lg:max-w-3xl leading-tight"
        v-html="title"
      />
      <p
        v-if="showSubline"
        class="mt-4 text-base sm:text-xl text-base-500 max-md:max-w-full max-w-2xl lg:max-w-3xl"
        v-html="subline"
      />
    </div>
    <IFrameBasedVideoPlayer
      :url="url"
      container-classes="w-full flex items-center justify-center"
      :iframe-classes="props.iframeClasses"
    />
  </SectionLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'VideoSection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import { computed, onMounted, ref, defineProps } from 'vue'
import { isEmpty } from 'lodash-es'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import { useRoute } from 'vue-router'
import type { TVideoSection } from '@/domain/Content/contracts/TVideoSection'
import type { TVideoSectionRemoteConfigData } from '@/domain/Content/contracts/TVideoSectionRemoteConfigData'
import { EBuilderIoModelTypes } from '@/app/contracts/EBuilderIoModelTypes'
import IFrameBasedVideoPlayer from '@/domain/Content/components/IFrameBasedVideoPlayer.vue'

const props: TVideoSection = withDefaults(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defineProps<TVideoSection & { attributes?: any }>(),
  {
    sectionId: undefined,
    modelType: EBuilderIoModelTypes.data,
    title: undefined,
    subline: undefined,
    containerClasses: 'py-3 lg:py-4',
    iframeClasses: 'w-full md:max-w-screen-md xl:max-w-screen-lg',
    url: undefined,
    remoteConfig: undefined,
    attributes: undefined,
  },
)

const title = ref<string>()
const subline = ref<string>()
const url = ref<string>()
const sectionId = ref<string>()
const containerClasses = ref<string>()
const iframeClasses = ref<string>()
const showSubline = computed(() => !isEmpty(subline.value))

onMounted(async () => {
  let fetchedData: TVideoSectionRemoteConfigData = null

  if (
    props.modelType === EBuilderIoModelTypes.data &&
    props.remoteConfig?.shouldFetch &&
    props.remoteConfig?.searchType === 'route'
  ) {
    fetchedData = ((
      await fetchOneEntry({
        apiKey: useConfig().get().builderIO.publicApiKey,
        model: CBuilderModels.VideoSection,
        query: {
          data: {
            routeOrPath: { $eq: useRoute().name },
          },
        },
      })
    )?.data ?? null) as TVideoSectionRemoteConfigData
  }

  sectionId.value = (fetchedData?.sectionId ?? props.sectionId ?? '').replace('#', '')
  title.value = fetchedData?.title ?? props.title
  subline.value = fetchedData?.subline ?? props.subline
  containerClasses.value = fetchedData?.containerClasses ?? props.containerClasses
  iframeClasses.value = fetchedData?.iframeClasses ?? props.iframeClasses
  url.value = fetchedData?.url ?? props.url
})
</script>

<style scoped></style>
