<template>
  <CardMenuBaseAction
    v-if="!error"
    id="edit-title"
    aria-label="Edit title"
    @click="activateEditTitle"
  >
    <PencilIcon class="h-5 w-5" />
    Edit title
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuTitleEditButton'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { useCardTitleEdit } from '@/domain/cards/services/useCardTitleEdit'
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'
import { PencilIcon } from '@heroicons/vue/outline'

const { activateEditTitle, error } = useCardTitleEdit()
</script>
