import { CRouteNames } from '@/app/contracts/CRouteNames'
import { applyShareLink, getSharingInformation } from '@/domain/cards/services/cardClient'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { useSharedTopicsStore } from '@/app/services/useSharedTopicsStore'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import type { AxiosError } from 'axios'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { truncate } from 'lodash-es'
import { CAuthenticationRouteNames } from '@/domain/Authentication'
import { useUrlSearchParams } from '@vueuse/core'
import { ref } from 'vue'
import type { TSharingInformation } from '@/domain/cards/contracts/TSharingInformation'
import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'

const resolveDocumentTitle = (information: TSharingInformation | null) =>
  truncate(information?.data.attributes.documentTitle ?? '', {
    length: 50,
    omission: '...',
  })

export const shareTopicNavigationGuard = async (to, from, next) => {
  const shareId = to.params?.shareId as string
  const { exposeOneNotification, removeExposedNotification } = useNotificationsStore()

  const information = ref<TSharingInformation | null>()

  try {
    information.value = await getSharingInformation(shareId)
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { status } = ((error as AxiosError)?.response ?? {}) as any

    if (status === 404) {
      const notification = createNotification(
        `The case you're trying to access is not found`,
        'error',
      )
      exposeOneNotification(notification, 10)
    }

    captureWithUser(EUserEvents.visited, {
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
      guide_share_link: to.fullPath,
      guide_share_link_id: shareId,
      guard: 'shareTopicNavigationGuard',
      comment: `Case could not be found redirect to '${CAuthenticationRouteNames.signIn}'`,
    })

    next({
      name: CAuthenticationRouteNames.signIn,
      replace: true,
    })
    return
  }

  captureWithUser(EUserEvents.selectedACase, {
    case_title: information.value?.data.attributes.documentTitle,
    case_type: ESignInContentTypes.case,
    case_snapshot_id: information.value?.data.attributes.documentSnapshotId,
    route: to.name,
    route_query: { ...to.query },
    route_params: { ...to.params },
    guard: 'shareTopicNavigationGuard',
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let response: any = undefined
  try {
    response = await applyShareLink(shareId)
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { status, data } = ((error as AxiosError)?.response ?? {}) as any

    if (status === 404) {
      const notification = createNotification(
        `The case you're trying to access is not found`,
        'error',
      )
      exposeOneNotification(notification, 10)

      captureWithUser(EUserEvents.visited, {
        route: to.name,
        route_query: { ...to.query },
        route_params: { ...to.params },
        guide_share_link: to.fullPath,
        guide_share_link_id: shareId,
        guard: 'shareTopicNavigationGuard',
        comment: `Case could not be found redirect to '${CRouteNames.home}'`,
      })

      next({
        name: CRouteNames.home,
        replace: true,
      })
      return
    }

    const documentTitle = resolveDocumentTitle(information.value)

    if (status && data && status === 401 && data?.errors[0]?.code === '4370cc81') {
      useSharedTopicsStore().storeTopic(shareId)

      const notification = createNotification(
        `Please login to access "${documentTitle}"`,
        'error',
      )
      exposeOneNotification(notification, 10)

      captureWithUser(EUserEvents.visited, {
        route: to.name,
        route_query: { ...to.query },
        route_params: { ...to.params },
        guide_share_link: to.fullPath,
        guide_share_link_id: shareId,
        guard: 'shareTopicNavigationGuard',
        comment: `Authentication is required to access case redirect to '${CAuthenticationRouteNames.signIn}'`,
      })

      next({
        name: CAuthenticationRouteNames.signIn,
        query: {
          share_id: shareId,
          ...useUrlSearchParams('history'),
        },
        replace: true,
      })
      return
    }

    if (status && data && status === 403 && data?.errors[0]?.code === 'b7964c30') {
      const notification = createNotification(
        `The link of the case "${documentTitle}" you're trying to access is already been expired`,
        'error',
      )
      exposeOneNotification(notification, 10)

      captureWithUser(EUserEvents.visited, {
        route: to.name,
        route_query: { ...to.query },
        route_params: { ...to.params },
        guide_share_link: to.fullPath,
        guide_share_link_id: shareId,
        guide_document_title: documentTitle,
        guard: 'shareTopicNavigationGuard',
        comment: `Case link already expired redirect to '${CRouteNames.home}'`,
      })

      next({
        name: CRouteNames.home,
        replace: true,
      })
      return
    }

    const notification = createNotification(
      `The link you're trying to access is either already expired used or not found`,
      'error',
    )
    exposeOneNotification(notification, 5)

    captureWithUser(EUserEvents.visited, {
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
      guide_share_link: to.fullPath,
      guide_share_link_id: shareId,
      guide_document_title: documentTitle,
      guard: 'shareTopicNavigationGuard',
      comment: `Case could not be found redirect to '${CAuthenticationRouteNames.signIn}'`,
    })

    next({
      name: CAuthenticationRouteNames.signIn,
      replace: true,
    })
    return
  }

  const applyShareLinkResponse = response
  const topicId = applyShareLinkResponse?.data?.relationships?.topic?.data?.id ?? null
  const isCopy = applyShareLinkResponse?.data?.attributes?.isCopy ?? null

  if (isCopy === true || topicId === null) {
    const typeName =
      information.value?.data.attributes.type === 'copy' ? 'copy' : 'one-time copy'
    const notification = createNotification(
      `Creating ${typeName} of "${resolveDocumentTitle(information.value)}"`,
      'info',
      {
        text: 'Refresh dashboard',
        doAct: async () => {
          await useTopicsStore().findAllTopics()
          // @todo track refresh clicks
        },
      },
    )
    exposeOneNotification(notification, 5)

    next({
      name: CRouteNames.dashboard,
      replace: true,
    })
    return
  }

  const notification = createNotification(
    `Added you as collaborator to "${
      information.value?.data.attributes.documentTitle ?? 'unknown'
    }"`,
    'info',
    {
      text: 'Go to topic',
      doAct: async () => {
        location.replace(`/topic/${topicId}`)
        removeExposedNotification()
      },
    },
  )
  exposeOneNotification(notification, 5)

  next({
    name: CRouteNames.dashboard,
    replace: true,
  })
}
