import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/prompt/components/PromptInput.vue'

export const PromptInputRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'Prompt Input',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'scrollTargetId',
      type: 'string',
      defaultValue: 'prompt-input-scroll-target',
    },
    {
      name: 'remoteConfig',
      type: 'object',
      defaultValue: {
        searchType: 'route',
        shouldFetch: true,
      },
      subFields: [
        {
          name: 'searchType',
          type: 'string',
        },
        {
          name: 'shouldFetch',
          type: 'boolean',
          defaultValue: false,
        },
      ],
    },
  ],
})
