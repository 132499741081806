<template>
  <SectionLayout
    width="narrow"
    :visualize-width="false"
    tag="section"
    class="mt-4 lg:mt-8"
    :class="[props.containerClasses, componentName]"
  >
    <div
      v-if="!isEmpty(props.title)"
      class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12"
    >
      <h2
        class="mb-4 text-4xl tracking-tight font-extrabold text-base-content"
        v-html="props.title"
      />
      <div v-if="props.sublines.length > 0" class="flex flex-col space-y-2">
        <p
          v-for="(sub, index) in props.sublines"
          :key="index"
          class="font-light text-base-500 sm:text-xl"
          v-html="sub.text"
        />
      </div>
    </div>
    <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
      <slot></slot>
    </div>
  </SectionLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PricingTableSection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { isEmpty } from 'lodash-es'
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import type { TPricingTableSection } from '@/domain/Content/contracts/TPricingTableSection'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = withDefaults(defineProps<TPricingTableSection & { attributes?: any }>(), {
  title: undefined,
  sublines: () => [],
  containerClasses: undefined,
})
</script>

<style scoped></style>
