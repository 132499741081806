const options = {
  scrollBehavior: 'smooth',
}
export const useScrollIntoView = () => {
  return {
    scroll: (
      scrollableContainer: HTMLElement | string,
      source: HTMLElement,
      bounding: 'self' | 'parent' | 'grandparent' | string,
    ) => {
      const container =
        typeof scrollableContainer === 'string'
          ? document.querySelectorAll(scrollableContainer)[0]
          : scrollableContainer

      let target: HTMLElement | null = source

      if (bounding === 'parent' && source.parentElement !== null) {
        target = source.parentElement as HTMLElement
      }

      if (
        bounding === 'grandparent' &&
        source.parentElement !== null &&
        source.parentElement.parentElement !== null
      ) {
        target = source.parentElement.parentElement as HTMLElement
      }

      if (bounding.startsWith('.') || bounding.startsWith('#')) {
        target = source.closest(bounding)
      }

      if (source.getAttribute('data-scroll-target-id')) {
        target = document.getElementById(
          source.getAttribute('data-scroll-target-id') as string,
        )
      }

      const containerScrollTop = container.scrollTop
      const containerTop = container.getBoundingClientRect().top
      const targetTop = target?.getBoundingClientRect()?.top ?? 0
      const distance = containerScrollTop + targetTop - containerTop

      container.scrollTo({
        top: distance,
        left: 0,
        behavior: options.scrollBehavior as ScrollBehavior,
      })
    },
  }
}
