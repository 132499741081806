import { computed } from 'vue'
import { resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from '@/app/services/metaDataClient'
import { Card } from '@/types'
import { ComputedRef } from 'vue'

export const createIsExplainANodeInProcess = (cardData: Card): ComputedRef<boolean> => {
  return computed(() => {
    const explainANodeMetadata = resolveMetaDataFromCard(
      cardData,
      metaDataTypes.explainANode,
    )

    if (!explainANodeMetadata) {
      return false
    }

    return (
      explainANodeMetadata?.attributes.payload?.status &&
      !['undefined', 'nodes-created'].includes(
        explainANodeMetadata?.attributes.payload.status,
      )
    )
  })
}
