import { MaybeElementRef, useFocus } from '@vueuse/core'
import { ref, toRaw, watch } from 'vue'

const focusTarget = ref()
const isFocused = ref(false)
const SCROLL_TARGET_ID = 'prompt-input-scroll-target'
export const usePromptFocus = () => {
  const init = (target: MaybeElementRef) => {
    focusTarget.value = target
    const { focused } = useFocus(focusTarget.value)

    watch(focused, (newFocusValue) => {
      isFocused.value = newFocusValue
    })
  }

  return {
    init,
    SCROLL_TARGET_ID,
    isFocused,
    doFocus: () => {
      useFocus(focusTarget.value).focused.value = true
    },
  }
}
