import { onMounted, onUnmounted, Ref, ref } from 'vue'
import { type TInputFixOptions } from '@/app/contracts/TInputFixOptions'
import { useScrollIntoView } from '@/app/composables/useScrollIntoView'
import { useScrollSpacer } from '@/app/composables/useScrollspacer'

export const useInputFix = (
  inputSelectors: string[],
  shouldActivate: () => boolean,
  options: TInputFixOptions,
) => {
  const lastFocusOutTimeStampRef = ref(0)
  const inputSelectorsRef: Ref<string[]> = ref(inputSelectors)
  const optionsRef: Ref<TInputFixOptions> = ref(options)

  if (!shouldActivate()) {
    return
  }

  const { activate: activateScrollSpacer, deactivate: deactivateScrollSpacer } =
    useScrollSpacer(
      optionsRef.value.scrollableContainer,
      optionsRef.value.scrollSpacer?.id as string,
      optionsRef.value.scrollSpacer?.classes as string[],
    )

  const applyMobileScrollFocusFix = (element: HTMLElement) => {
    element.style.transform = 'translateY(-99999px)'
    useScrollIntoView().scroll(
      optionsRef.value.scrollableContainer,
      element,
      optionsRef.value.scrollTarget,
    )
    element.focus()

    setTimeout(() => {
      element.style.transform = ''
    }, 50)
  }

  const onFocusIn = (e: Event) => {
    // if (!(e.target as HTMLElement).classList.contains('PromptInput')) {
    //   @info exclude these elements elements
    //   return
    // }

    activateScrollSpacer()
    const target = e.target as HTMLElement
    applyMobileScrollFocusFix(target)

    // if (e.timeStamp - lastFocusOutTimeStampRef.value < 50) {
    //   @info jump between input elements detected they happen in single digit ms speed
    //   return
    // }
  }

  const onFocusOut = (e: Event) => {
    lastFocusOutTimeStampRef.value = e.timeStamp

    deactivateScrollSpacer()
  }

  const activateFix = () => {
    inputSelectorsRef.value.forEach((selector) => {
      document
        .querySelectorAll(selector)
        .forEach((element: Element) =>
          (element as HTMLElement).addEventListener('focusin', onFocusIn),
        )
      document
        .querySelectorAll(selector)
        .forEach((element) =>
          (element as HTMLElement).addEventListener('focusout', onFocusOut),
        )
    })
  }

  const resetValues = () => {
    lastFocusOutTimeStampRef.value = 0
    inputSelectorsRef.value = []
  }

  const deactivateFix = () => {
    inputSelectorsRef.value.forEach((selector) => {
      document
        .querySelectorAll(selector)
        .forEach((element) => element.removeEventListener('focusin', onFocusIn))
      document
        .querySelectorAll(selector)
        .forEach((element) => element.removeEventListener('focusout', onFocusOut))
    })

    resetValues()
  }

  onMounted(() => activateFix())
  onUnmounted(() => deactivateFix())
}
