<template>
  <CardMenuBaseAction
    v-for="(item, index) in cardStates"
    :key="index"
    class="flex w-[224px] items-center gap-2 px-4 py-2 font-normal text-base-content hover:bg-base-300 hover:text-state-error focus:outline-none focus:ring-0"
    :aria-label="item.label"
    @click="applyState(props.cardData, item.value)"
  >
    <span
      class="h-5 w-5 rounded-full"
      :class="[
        item.background,
        item.value === CardStateIds.noStatus ? 'border border-base-400' : '',
      ]"
    />
    {{ item.label }}
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuActionApplyState'

export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { Card } from '@/types'
import { CardStateIds, cardStates } from '@/app/support/cardStates'
import {
  getMetaDataValue,
  resolveMetaDataFromCard,
  setMetaDataValue,
} from '@/domain/cards/support/helper'
import { metaDataTypes, updateMetaData } from '@/app/services/metaDataClient'
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'

const props = withDefaults(defineProps<{ cardData: Card }>(), {
  cardData: {} as Card,
})

const applyState = async (card: Card, cardStateId: string) => {
  const stateMetaData = resolveMetaDataFromCard(card, metaDataTypes.stateId)
  const oldValue = getMetaDataValue(stateMetaData)
  setMetaDataValue(stateMetaData, cardStateId)
  try {
    await updateMetaData(stateMetaData.id, cardStateId)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('🚨', 'failed to update card state metaData', error, { card })
    setMetaDataValue(stateMetaData, oldValue)
  }
}
</script>
