<template>
  <div class="flex justify-center px-8">
    <div
      class="my-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 min-w-xs max-w-7xl"
    >
      <Card
        v-for="(topic, index) in filteredBySearch"
        :key="topic.id"
        :style="{ zIndex: filteredBySearch.length - index }"
        class="relative z-20 m-2.5"
        :card-data="topic"
        type="topic"
        @card-clicked="openTopicAction(topic.id, topic)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Card from '@/domain/cards/components/Card.vue'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useDashboardSearchStore } from '@/app/services/useDashboardSearchStore'
import { createFuse } from '@/app/services/fuseFactory'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { usePosthog } from '@/app/support/usePosthog'
import { EGuideEvents } from '@/app/contracts/EGuideEvents'

export const componentName = 'CardListingTopic'
export default defineComponent({
  name: componentName,
  components: { Card },
  setup() {
    const topicsStore = useTopicsStore()
    const { sortedTopics: topics } = storeToRefs(topicsStore)

    const router = useRouter()
    const openTopicAction = (topicId: string, topic) => {
      const template =
        topic?.relationships?.metadata?.data?.filter(
          (m) =>
            m?.relationships?.metadataType?.data?.id ===
            'f6908079-cf09-4312-9632-2c113da5ddde',
        )[0]?.attributes?.payload ?? {}
      const guideOpenedEventPayload = {
        guide_id: topicId,
        guide_title:
          topic?.relationships?.metadata?.data?.filter(
            (m) =>
              m?.relationships?.metadataType?.data?.id ===
              '2b754052-a2ae-430f-afb3-60ab0a242045',
          )[0]?.attributes?.payload?.value ?? null,
        guide_template_id: template?.templateId ?? null,
        guide_template_source_id: template?.sourceId ?? null,
      }
      usePosthog().capture(EGuideEvents.opened, guideOpenedEventPayload)
      router.push({ name: CRouteNames.topic, params: { topicId: topicId } })
    }

    const filtered = computed(() => {
      const { topicsSearchString } = storeToRefs(useDashboardSearchStore())
      return topicsSearchString.value
        ? createFuse(topics.value)
            .search(topicsSearchString.value)
            .map((result) => result.item)
        : topics.value
    })

    return {
      openTopicAction,
      componentName,
      filteredBySearch: filtered,
    }
  },
})
</script>

<style scoped>
.w-card {
  @apply w-full m-0;
}
</style>
