<template>
  <textarea
    v-if="editing && multiline"
    ref="inputEl"
    class="m-0 h-full w-full resize-none border-0 bg-transparent p-0 focus:outline-none focus:ring-0 focus-visible:outline-none overflow-y-scroll"
    :class="[$attrs.class, componentName]"
    type="textarea"
    wrap="hard"
    :maxlength="maxlength"
    :value="computedValue"
    :aria-label="inputAriaLabel"
    :placeholder="computedPlaceholder"
    rows="1"
    @keydown.enter.stop="handleKeyDownEnter"
    @blur="handleInputChanged"
    @click.stop.prevent=""
    @input="resizeTextArea($event.target)"
  />
  <input
    v-if="editing && !multiline"
    ref="inputEl"
    class="m-0 w-full border-0 bg-transparent p-0 focus:outline-none focus:ring-0 focus-visible:outline-none"
    :class="[$attrs.class, componentName]"
    type="text"
    :value="computedValue"
    :aria-label="inputAriaLabel"
    :placeholder="computedPlaceholder"
    :maxlength="maxlength"
    @keydown.enter.stop="handleKeyDownEnter"
    @blur="handleInputChanged"
  />
  <div
    v-if="!editing"
    ref="labelEl"
    class="min-h-[24px] w-full overflow-hidden hyphens-auto break-words"
    :class="[
      $attrs.class,
      multiline ? 'line-clamp-' + maxLines : 'truncate',
      componentName,
    ]"
    :aria-label="readonlyAriaLabel"
    :style="{ height: inputHeight }"
    @click.stop.prevent="
      !shouldDoubleClickToEdit && editByInlineClick
        ? toggle()
        : overrideSingleClickHandler
        ? overrideSingleClickHandler()
        : null
    "
    @dblclick.stop.prevent="
      shouldDoubleClickToEdit && editByInlineClick ? toggle() : null
    "
  >
    {{ computedLabel }}
  </div>
  <span
    v-if="!editByInlineClick"
    class="inline-block w-fit underline hover:cursor-text"
    :class="$attrs.class"
    :aria-label="resolvedAriaLabelForAction"
    @click.stop.prevent="toggle"
  >
    {{ actionTitle }}
  </span>
</template>

<script lang="ts">
import { computed, nextTick, ref, onMounted, watch, defineComponent } from 'vue'

const componentName = 'InlineInput'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    maxLines: {
      type: Number,
      default: () => 5,
    },
    maxlength: {
      type: Number,
      default: () => 144,
    },
    multiline: {
      type: Boolean,
      default: true,
    },
    editByInlineClick: {
      type: Boolean,
      default: true,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    shouldDoubleClickToEdit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isTitleEditing: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: null,
    },
    inputAriaLabel: {
      type: String,
      default: null,
    },
    readonlyAriaLabel: {
      type: String,
      default: null,
    },
    actionAriaLabels: {
      type: Array,
      default: () => [],
    },
    overrideSingleClickHandler: {
      type: Function,
      default: null,
    },
  },
  emits: [
    'inline-input-changed',
    'inline-input-is-editing',
    'inline-input-stopped-editing',
    'inline-input-keydown-enter',
    'update:isTitleEditing',
  ],
  setup(props, { emit }) {
    const labelEl = ref<HTMLLabelElement>()
    const inputEl = ref<HTMLInputElement>()

    const actionTitle = ref('edit')
    const editing = ref(false)

    watch(editing, async (value) => {
      actionTitle.value = value === false ? 'edit' : 'save'
      emit('update:isTitleEditing', value)
    })

    watch(
      () => props.isTitleEditing,
      (val) => {
        if (editing.value === val) {
          return
        }
        toggle()
      },
    )

    const toggle = async () => {
      editing.value = !editing.value

      if (editing.value) {
        emit('inline-input-is-editing', {
          componentName: componentName,
          isEditing: editing.value,
          value: inputEl?.value?.value ?? '',
        })

        await nextTick(() => {
          if (props.multiline) {
            resizeTextArea(inputEl.value!)
          }

          inputEl?.value?.focus()
        })
      }

      if (!editing.value) {
        emit('inline-input-stopped-editing', {
          componentName: componentName,
          isEditing: editing.value,
          value: inputEl?.value?.value ?? '',
        })
      }
    }

    const handleKeyDownEnter = (event: Event) => {
      event.preventDefault()
      inputEl?.value?.blur()
      emit('inline-input-keydown-enter', event)
    }

    const handleInputChanged = (event: Event) => {
      if (props.editByInlineClick === true) {
        toggle()
      }

      if ((event.target as HTMLInputElement).value !== computedValue.value) {
        emit('inline-input-changed', {
          componentName: componentName,
          event: event,
        })
      }
    }

    const computedLabel = computed(() => {
      return props.value === '' ? props.placeholder : props.value
    })

    const computedValue = computed(() => props.value)

    const computedPlaceholder = computed(() => props.placeholder)

    const inputHeight = ref('auto')

    const resizeTextArea = (element?: EventTarget | null) => {
      if (!element || !(element instanceof HTMLElement)) {
        return
      }
      element.style.height = 'auto'
      element.style.height = `${element.scrollHeight}px`
      inputHeight.value = element.scrollHeight + 'px'
    }

    const focusOnInput = () => {
      if (props.focusOnMount && editing.value === false) {
        toggle()
      }
    }

    onMounted(focusOnInput)

    watch(() => props.focusOnMount, focusOnInput)

    const resolvedAriaLabelForAction = computed((): any => {
      return props?.actionAriaLabels[editing.value ? 1 : 0] ?? null
    })

    return {
      inputHeight,
      componentName,
      resizeTextArea,
      labelEl,
      inputEl,
      computedLabel,
      editing,
      computedValue,
      computedPlaceholder,
      handleInputChanged,
      handleKeyDownEnter,
      toggle,
      actionTitle,
      resolvedAriaLabelForAction,
      dummyClick: () => {
        console.log('dummy click')
      },
    }
  },
})
</script>
