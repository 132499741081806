<template>
  <aside class="flex flex-col grow-0 justify-center items-center">
    <button
      class="flex grow-0 justify-center font-medium rounded-xl px-4 py-1 uppercase text-sm border"
      :class="
        props.isEnabled
          ? [
              'bg-state-error–content',
              'text-state-error',
              'border-state-error',
              'hover:bg-state-error-focus',
              'hover:border-state-error-focus',
              'hover:text-state-error-content',
            ]
          : ['bg-base-100', 'borer-base-300', 'text-base-300', 'cursor-not-allowed']
      "
    >
      Follow
    </button>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'FollowExpertButton'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
const props = withDefaults(defineProps<{ isEnabled?: boolean }>(), {
  isEnabled: true,
})
</script>

<style scoped></style>
