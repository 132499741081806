<template>
  <FlexibleSectionLayout
    v-if="props.content"
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <div>
      <div class="flex flex-col sm:flex-row justify-between items-start sm:items-end">
        <TextHeading
          title-tag="h2"
          :content="{ title: 'About the Author' }"
          :classes="{
            title: 'text-2xl !mt-2',
            subline: 'text-base font-normal',
          }"
          gap="mt-4"
        />

        <div class="flex flex-row gap-2 items-center text-base-500 font-medium">
          <span>Non-Affiliated</span>
          <span class="w-1 h-1 rounded-full bg-base-content"></span>
          <span>Miljn Curated</span>
        </div>
      </div>
      <hr class="mt-2 mb-6" />
      <div class="flex flex-row gap-6 items-start sm:items-center">
        <ProfileAvatarWithFallback
          :name="props.name"
          :image="props.image"
          class="w-16 h-16 sm:w-24 sm:h-24 border border-base-150"
        />

        <div class="flex flex-col gap-2">
          <TextHeading
            title-tag="h3"
            :content="{ title: props.name }"
            :classes="{
              title: 'text-xl font-bold !mt-0',
            }"
            gap="mt-4"
          />

          <p
            v-if="!isEmpty(props?.content)"
            class="text-base font-normal text-base-500"
            v-html="props?.content"
          />
        </div>
      </div>
    </div>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'CaseSourceAuthorSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { isEmpty } from 'lodash-es'
import type { TCaseSourceAuthorContent } from '@/domain/Content/contracts/TCaseSourceAuthorContent'
import ProfileAvatarWithFallback from '@/domain/Content/components/ProfileAvatarWithFallback.vue'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    TCaseSourceAuthorContent & {
      containerClasses?: string
      contentContainerClasses?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & { attributes?: any }
  >(),
  {
    containerClasses: undefined,
    contentContainerClasses: 'flex-col gap-6 justify-center items-start',
  },
)

const containerClassesComputed = computed(() => cva()({ class: props.containerClasses }))

const contentContainerClassesComputed = computed(() =>
  cva()({ class: props.contentContainerClasses }),
)
</script>

<style scoped></style>
