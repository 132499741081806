import { onMounted, onUnmounted, type Ref, ref, watch } from 'vue'
import type { TDirectoryEntry } from '@/domain/directory/contracts/TDirectoryEntry'
import { fetchEntries } from '@builder.io/sdk-vue'
import { directoryEntriesDataTransformer } from '@/domain/directory/services/directoryEntriesDataTransformer'
import type { TDirectoryEntryData } from '@/domain/directory/contracts/TDirectoryEntryData'
import type { TDocumentCategory } from '@/domain/directory/contracts/TDocumentCategory'
import { categoryDataTransformer } from '@/domain/directory/services/categoryDataTransformer'
import type { TDocumentCategoryData } from '@/domain/directory/contracts/TDocumentCategoryData'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'

export const useDirectoryEntries = (apiKey: string, useFakeData = false) => {
  const categories = ref<TDocumentCategory[]>([])
  const entries = ref<TDirectoryEntry[]>([])
  const selectedCategoryIds = ref<string[]>([])
  const selectedExpertIds = ref<string[]>([])

  const reset = () => {
    categories.value = []
    entries.value = []
    selectedCategoryIds.value = []
    selectedExpertIds.value = []
  }

  const fetchCategories = async (apiKey: string): Promise<TDocumentCategory[]> => {
    if (useFakeData) {
      return (await import('@/domain/directory/data/categories.json')).default
    }

    const result =
      (await fetchEntries({
        apiKey: apiKey,
        model: 'document-categories',
      })) ?? []

    return result.map(
      (item) =>
        categoryDataTransformer(item as TDocumentCategoryData) as TDocumentCategory,
    )
  }

  const fetchDirectoryEntries = async (
    apiKey: string,
    selectedExpertIds: Ref<string[]>,
    selectedFilterIds: Ref<string[]>,
  ): Promise<TDirectoryEntry[]> => {
    if (useFakeData) {
      return (await import('@/domain/directory/data/directory-entries.json'))
        .default as TDirectoryEntry[]
    }

    const query = {}

    if (selectedExpertIds.value.length > 0) {
      query['data.experts.expert.id'] = { $in: selectedExpertIds.value }
    }

    if (selectedFilterIds.value.length > 0) {
      query['data.category.id'] = { $in: selectedFilterIds.value }
    }

    const result =
      (await fetchEntries({
        apiKey: apiKey,
        model: CBuilderModels.DirectoryEntries,
        enrich: true,
        limit: 100,
        query,
      })) ?? []

    return result.map((dataItem) =>
      directoryEntriesDataTransformer(dataItem as TDirectoryEntryData),
    )
  }

  onMounted(async () => {
    categories.value = await fetchCategories(apiKey)
    entries.value = await fetchDirectoryEntries(
      apiKey,
      selectedExpertIds,
      selectedCategoryIds,
    )
  })

  onUnmounted(() => {
    reset()
  })

  watch(selectedCategoryIds, async () => {
    entries.value = await fetchDirectoryEntries(
      apiKey,
      selectedExpertIds,
      selectedCategoryIds,
    )
  })

  watch(selectedExpertIds, async () => {
    entries.value = await fetchDirectoryEntries(
      apiKey,
      selectedExpertIds,
      selectedCategoryIds,
    )
  })

  return {
    categories,
    selectedCategoryIds,
    selectedExpertIds,
    entries,
  }
}
