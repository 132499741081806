import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export const useCardTitle = (cardData: any, titleResolver: (cardData: any) => string) => {
  const title = ref<string>()

  const { query } = useRoute()

  onMounted(() => {
    if (!title.value && query?.prompt) {
      title.value = query?.prompt as string
    }
  })

  watch(cardData, (newCardData) => {
    if (Object.keys(newCardData).length <= 0) {
      return
    }

    title.value = titleResolver(newCardData)
  })

  return {
    title,
  }
}
