<template>
  <div
    id="base-layout"
    class="relative flex w-screen flex-col md:h-screen"
    :class="componentName"
  >
    <div ref="headerRef" class="z-20 fixed w-full">
      <slot name="navigation" />
    </div>
    <div
      class="relative z-0 flex grow flex-col"
      :class="[overflowClass]"
      :style="{
        marginTop: headerHeight + 'px',
      }"
    >
      <slot name="content" />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'BaseAppLayout'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { useElementBounding } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useDirectCssClassOverride } from '@/app/composables/useDirectCssClassOverride'

type TBaseLayoutProps = {
  canScroll?: boolean
}
const props = withDefaults(defineProps<TBaseLayoutProps>(), {
  canScroll: false,
})

const headerRef = ref(null)
const { height: headerHeight } = useElementBounding(headerRef)

const overflowClass = computed(() => {
  return props.canScroll ? 'overflow-auto' : 'overflow-hidden'
})

useDirectCssClassOverride(['html', 'body'], ['bg-base-150'])
</script>
