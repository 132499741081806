import mem from 'mem'
import { log } from '@/app/services/errorService'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'

// @todo rework this implementation because an new JWT should never be provided by the old one
// better approach would be a 'refresh_token' + 'jwt' to request a new jwt
const remoteJwtResolver = async (apiClientInstance) => {
  try {
    const oldJwt = useAuthentication().jwt.value
    return oldJwt
  } catch (error) {
    useAuthentication().removeJwt()
    log(error)
  }
}

export const memoizedRemoteJwtResolver = (apiClientInstance, maxAge: 10000) =>
  mem(() => remoteJwtResolver(apiClientInstance), {
    maxAge,
  })
