export function awaitableResponse<T>(
  responseValue: T,
  ms: number,
  logWaiting?: boolean,
): Promise<T> {
  // eslint-disable-next-line no-console
  logWaiting &&
    console.log('⏱️ - 🚀', 'awaitableResponse called with', { ms, responseValue })
  return new Promise((resolve) =>
    setTimeout(() => {
      // eslint-disable-next-line no-console
      logWaiting &&
        console.log('⏱️ - 🏁 ', 'awaitableResponse resolved after', { ms, responseValue })
      return resolve(responseValue)
    }, ms),
  )
}

export const awaitableSleep = (ms: number, logWaiting = true) =>
  awaitableResponse<void>(undefined, ms, logWaiting)
