import { computed, ref, watch } from 'vue'
import type { BuilderContent } from '@builder.io/sdk-vue/lib/node/types/builder-content'

import {
  fetchOneEntry,
  getBuilderSearchParams,
  isEditing,
  isPreviewing,
  RegisteredComponent,
} from '@builder.io/sdk-vue'
import { EFetchOutcome } from '@/app/contracts/EFetchOutcome'
import type { GetContentOptions } from '@builder.io/sdk-vue/lib/node/functions/get-content/types'
import { mergeDeep } from '@tiptap/core'
import { useConfig } from '@/app/services/useConfig'

export const useOnePageEntry = (
  modelName: string,
  supportedComponents: RegisteredComponent[] = [],
) => {
  const _config = {
    modelName,
    apiKey: useConfig().get().builderIO.publicApiKey,
    getComponents: () => supportedComponents,
  }

  const content = ref<BuilderContent | null | undefined>(undefined)
  const _fetchOutcome = ref<EFetchOutcome>(EFetchOutcome.UNDEFINED)

  watch(content, (newValue) => {
    if (newValue === undefined) {
      _fetchOutcome.value = EFetchOutcome.UNDEFINED
      return
    }

    _fetchOutcome.value =
      isEditing() || isPreviewing() || newValue !== null
        ? EFetchOutcome.SHOW_CONTENT
        : EFetchOutcome.SHOW_FALLBACK
  })

  return {
    content,
    config: computed(() => _config),
    fetchOutcome: computed(() => _fetchOutcome.value),
    doFetch: async (options: Partial<GetContentOptions> = {}) => {
      const resolvedOptions: GetContentOptions = mergeDeep(
        {
          model: _config.modelName,
          apiKey: _config.apiKey,
          options: getBuilderSearchParams(new URL(location.href).searchParams),
          userAttributes: {
            urlPath: window.location.pathname,
          },
        },
        options,
      ) as GetContentOptions

      content.value = await fetchOneEntry(resolvedOptions)
    },
  }
}
