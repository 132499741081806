<template>
  <section
    class="flex flex-col w-full items-center"
    :class="[containerClasses, componentName]"
  >
    <h1
      class="font-extrabold tracking-tight text-base-content max-w-2xl lg:max-w-3xl leading-tight"
      :class="[titleClasses]"
      v-html="title"
    />
    <p
      v-if="showSubline"
      class="mt-4 text-base-500 max-md:max-w-full max-w-2xl lg:max-w-3xl"
      :class="[sublineClasses]"
      v-html="subline"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'TextHero'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { type TTextHero } from '@/app/contracts/TTextHero'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import type { TTextHeroRemoteConfigData } from '@/app/contracts/TTextHeroRemoteConfigData'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = withDefaults(defineProps<TTextHero & { attributes?: any }>(), {
  title: 'Create your guide with AI',
  titleClasses: 'text-3xl lg:text-5xl',
  subline:
    'Act like a pro. Save time. Get tailored guides to your situation. Stay on target.',
  sublineClasses: 'text-base lg:text-xl',
  containerClasses: 'py-3 lg:py-4',
  showSubline: false,
  attributes: undefined,
  remoteConfig: undefined,
})

const title = ref<string>()
const titleClasses = ref<string>()
const subline = ref<string>()
const sublineClasses = ref<string>()
const showSubline = ref(false)
const containerClasses = ref<string>()

onMounted(async () => {
  let fetchedData: TTextHeroRemoteConfigData = null

  if (props.remoteConfig?.shouldFetch && props.remoteConfig?.searchType === 'route') {
    fetchedData = ((
      await fetchOneEntry({
        apiKey: useConfig().get().builderIO.publicApiKey,
        model: CBuilderModels.TextHero,
        query: {
          data: {
            routeOrPath: { $eq: useRoute().name },
          },
        },
      })
    )?.data ?? null) as TTextHeroRemoteConfigData
  }

  title.value = fetchedData?.title ?? props.title
  titleClasses.value = fetchedData?.titleClasses ?? props.titleClasses
  subline.value = fetchedData?.subline ?? props.subline
  sublineClasses.value = fetchedData?.sublineClasses ?? props.sublineClasses
  showSubline.value = fetchedData?.showSubline ?? props.showSubline
  containerClasses.value = fetchedData?.containerClasses ?? props.containerClasses
})
</script>

<style scoped></style>
