import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { metaData } from '@/app/services/miljnApiEndpointsWeb2'

export enum metaDataTypes {
  titleId = '2b754052-a2ae-430f-afb3-60ab0a242045',
  stateId = 'b6e6311c-343b-4b79-bf45-113b8b20186e',
  sharedAsCopyId = 'f6908079-cf09-4312-9632-2c113da5ddde',
  extendDocumentWithAiId = '5f303959-f0f7-4548-aedd-717f94d5b794',
  detailANodeWithAdditionalSteps = '3a58a53d-7fbb-4d33-ad1a-5253ed6888d4',
  explainANode = 'd05908aa-ff5e-448c-97a7-9bf5ca5437aa',
}

export const updateMetaData = async (
  id: string,
  value: any,
  useValue = true,
): Promise<void> => {
  const instance = miljnApiBaseClientFactory()

  const requestBody = {
    data: {
      id: id,
      type: 'metadata',
      attributes: {
        payload: useValue ? { value: value } : value,
      },
    },
  }

  return await instance.patch(metaData.update(id), requestBody)
}

export default {
  metaDataTypes,
  updateMetaData,
}
