<template>
  <section class="flex justify-center" :class="componentName">
    <div
      class="flex flex-col items-stretch min-w-xs w-full max-w-2xl lg:max-w-3xl px-4"
      :class="useProse ? proseClass : undefined"
    >
      <slot></slot>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'ContentCanvas'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import { EProseTypes } from '@/app/contracts/EProseTypes'

const props = withDefaults(
  defineProps<
    {
      useProse: boolean
      proseType?: EProseTypes
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    useProse: true,
    proseType: EProseTypes.marketing,
  },
)

// eslint-disable-next-line no-undef
const proseClass = computed(() => {
  return props.proseType === EProseTypes.default
    ? 'prose'
    : `prose prose-${props.proseType}`
})
</script>

<style>
.prose.prose-marketing h2 {
  font-weight: 200;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.prose.prose-marketing h3 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  line-height: 1.2;
}
</style>
