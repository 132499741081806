import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'
import type { TCreateAuthenticationDocumentRequest } from '@/domain/Authentication/contracts/TCreateAuthenticationDocumentRequest'
import { v4 as uuid } from 'uuid'
import type { TAuthenticationDocument } from '@/domain/Authentication/contracts/TAuthenticationDocument'
import { EAuthenticationSecretTypes } from '@/domain/Authentication/contracts/EAuthenticationSecretTypes'
import { ref } from 'vue'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'

export const requestAuthenticationDocumentBySecretType = async (
  secretType: EAuthenticationSecretTypes,
  payload: string,
  query?: TSignInContext & { oauth_verifier?: string },
): Promise<TAuthenticationDocument | undefined> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'authentication-documents')

  const requestPayload: { data: TCreateAuthenticationDocumentRequest } = {
    data: {
      id: uuid(),
      type: 'createAuthenticationDocumentRequests',
      attributes: {
        type: secretType,
        payload: payload,
      },
    },
  }

  const url = ref('/authentication')
  if (query) {
    const queryParams = new URLSearchParams(query).toString()
    url.value = queryParams ? `${url.value}?${queryParams}` : url.value
  }

  try {
    const response = await instance.post(url.value, requestPayload, requestConfig)

    if (response.status === 204) {
      return undefined
    }

    return response?.data?.data
  } catch (error) {
    throw new Error('Requesting the JWT failed with status code 500')
  }
}
