<template>
  <span
    v-if="isVisible"
    aria-label="Shared Badge"
    class="rounded-full border px-4 py-1 text-sm font-medium"
    :class="[cardStateBasedStylingClasses, componentName]"
  >
    Shared
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export const componentName = 'CardIsSharedForCollaborationBadge'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import {
  CardStateIds,
  resolveCardState,
  resolveDefaultCardState,
} from '@/app/support/cardStates'
import { Card } from '@/types'
import { resolveCardStateId } from '@/domain/cards/support/helper'

const props = withDefaults(
  defineProps<{ cardData: Card; context?: 'PTopic' | 'Card' }>(),
  {
    cardData: () => ({}),
    context: () => 'Card',
  },
)

const isVisible = computed(
  () =>
    props.cardData?.attributes?.cardType === 'topic' &&
    props.cardData?.relationships?.contributors?.data?.length > 1,
)

const cardStateBasedStylingClasses = computed(() => {
  if (props.context === 'PTopic') {
    return resolveCardState(CardStateIds.problem)?.sharedBadge
  }

  const cardState = resolveCardState(resolveCardStateId(props.cardData))
  return (cardState ?? resolveDefaultCardState())?.sharedBadge
})
</script>
