<template>
  <section v-if="props.people.length > 0" class="w-full" :class="[componentName]">
    <ul role="list" class="flex flex-col md:flex-row mt-4 gap-y-10 w-full">
      <li
        v-for="person in props.people"
        :key="person.name"
        class="flex flex-col md:w-1/3 items-center"
      >
        <img
          class="mx-auto h-50 max-w-[200px] w-50 rounded-2xl"
          :src="person.imageUrl"
          alt=""
        />
        <h3
          class="mt-6 mb-1 text-xl font-bold leading-7 tracking-tight text-base-content"
        >
          {{ person.name }}
        </h3>
        <p class="text-lg font-normal leading-6 text-base-500">{{ person.role }}</p>
        <p class="text-lg font-normal leading-6 text-base-500">
          {{ person.founderType }}
        </p>
        <ul role="list" class="mt-4 md:mt-6 flex justify-center gap-x-4 md:gap-x-6">
          <li v-if="person.xUrl">
            <a
              :href="person.xUrl"
              class="block text-base-content hover:scale-125 transition-transform duration-300 ease-in-out"
            >
              <SocialIcons type="X" :size="'w-7 h-7'" />
            </a>
          </li>
          <li v-if="person.linkedinUrl">
            <a
              :href="person.linkedinUrl"
              class="block text-[#0A66C2] hover:scale-125 transition-transform duration-300 ease-in-out"
            >
              <SocialIcons type="LinkedIn" :size="'w-7 h-7'" />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'TeamSection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { ref } from 'vue'
import SocialIcons from '@/app/components/SocialIcons.vue'

type TTeamMember = {
  name: string
  role?: string
  founderType?: string
  imageUrl?: string
  xUrl?: string
  linkedinUrl?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = withDefaults(
  defineProps<{ people: TTeamMember[] } & { attributes?: any }>(),
  {
    people: () => [],
  },
)

const people = ref([
  {
    name: 'Olaf Birkner',
    role: 'CEO',
    founderType: 'Founder',
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    xUrl: undefined,
    linkedinUrl: '#',
  },
  {
    name: 'Tim Logan',
    role: 'Product Owner',
    founderType: 'Co-Founder',
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    xUrl: undefined,
    linkedinUrl: '#',
  },
  {
    name: 'Denny Weiß',
    role: 'Technical Lead',
    founderType: 'Co-Founder',
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    xUrl: undefined,
    linkedinUrl: '#',
  },
  {
    name: 'Lars Schulze',
    role: 'Marketing Advisor',
    founderType: 'Co-Founder',
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    xUrl: undefined,
    linkedinUrl: '#',
  },
  {
    name: 'Björn Klotz',
    role: 'CFO',
    founderType: 'Co-Founder',
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    xUrl: undefined,
    linkedinUrl: '#',
  },
])
</script>

<style scoped></style>
