import { UAParser } from 'ua-parser-js'

const uaParser = new UAParser()

export const useUAParser = () => {
  return {
    instance: uaParser,
    isMobile: () => uaParser?.getDevice()?.type === 'mobile' ?? false,
    isSafari: () => uaParser?.getEngine().name === 'WebKit',
  }
}
