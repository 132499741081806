<template>
  <div
    v-if="notifications.lengh == 0 || exposedNotification"
    class="fixed right-2 top-[72px] z-[100] max-h-[80vh] w-full max-w-[364px]"
  >
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0 translate-x-12"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 translate-x-0"
      leave-to-class="transform opacity-0 translate-x-12"
    >
      <div v-if="exposedNotification">
        <Notification :notification="exposedNotification" @closed="onNotificationClose" />
      </div>
    </Transition>
    <UndoNotification />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import Notification from './Notification.vue'
import UndoNotification from './UndoNotification.vue'

export const componentName = 'ExposedNotificationDialog'

export default defineComponent({
  name: componentName,
  components: { Notification, UndoNotification },
  setup() {
    const notificationStore = useNotificationsStore()
    const { exposedNotification, notifications } = storeToRefs(notificationStore)
    const { removeExposedNotification } = notificationStore

    const onNotificationClose = () => {
      removeExposedNotification()
    }

    return {
      notifications,
      exposedNotification,
      onNotificationClose,
    }
  },
})
</script>
