import type { RouteRecordRaw } from 'vue-router'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import PExpert from '@/domain/directory/components/PExpert.vue'

export const directoryRoutes: RouteRecordRaw[] = [
  // @todo add `/experts` route
  {
    path: '/experts/:slug',
    name: CRouteNames.expert,
    component: PExpert,
  },
] satisfies RouteRecordRaw[]
