<template>
  <div
    class="relative mx-8 flex items-center justify-start py-2"
    :class="[props.isDashboard ? '' : 'mt-4', componentName]"
    :data-card-id="cardId"
  >
    <div v-if="props.isDashboard">
      <h1 class="my-2 mr-3 max-w-[640px] truncate text-lg leading-6 text-base-500">
        {{ props.title }}
      </h1>
    </div>

    <div v-else>
      <div v-if="showBreadcrumb" class="mb-2">
        <CardBreadcrumbs :current-card="parentCard" :current-position="position" />
      </div>

      <div
        class="flex cursor-pointer items-center justify-start gap-x-2"
        @click.stop.prevent="expandAsCard(cardData)"
      >
        <div class="flex items-center gap-x-4">
          <div
            class="h-9 w-[4.5rem] rounded-md grow-0 shadow-md"
            :class="[cardBackgroundColorClass]"
          ></div>
          <div
            class="flex grow items-center gap-x-2 text-xl text-base-content truncate"
            aria-label="Topic title"
          >
            <span v-if="position.length">{{ position }}.</span>
            <h2
              class="md:max-w-[64vw] truncate"
              :class="isGenerating ? ['max-w-[44vw]'] : ['max-w-[50vw]']"
            >
              {{ cardTitle }}
            </h2>
          </div>
        </div>

        <div class="flex">
          <CardIsSharedForCollaborationBadge
            class="hidden sm:block"
            :card-data="cardData"
            context="PTopic"
          />
          <CardMenu
            class="ml-auto"
            :used-in-title-context="true"
            :card-data="cardData"
            :type="'topic'"
          />
          <NodeProcessBadge
            class="shrink-0 bg-card-state-waiting"
            :label="'Generating ...'"
            :is-processing="isGenerating"
            :minimize-on-mobile="true"
          />
        </div>
      </div>
    </div>
    <slot v-if="props.isDashboard" name="header-actions" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CardIsSharedForCollaborationBadge from '@/app/components/CardIsSharedForCollaborationBadge.vue'

export const componentName = 'CardListingHeader'

export default defineComponent({
  name: componentName,
  components: {
    CardIsSharedForCollaborationBadge,
  },
})
</script>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue'
import CardBreadcrumbs from '@/domain/cards/components/CardBreadcrumbs.vue'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { storeToRefs } from 'pinia'
import { useUserLocationStore } from '@/app/services/useUserLocationStore'
import { useCardExpandedDialogStore } from '@/domain/cardExpanded/services/useCardExpandedDialogStore'
import {
  getMetaDataValue,
  resolveCardStateId,
  resolveMetaDataFromCard,
} from '@/domain/cards/support/helper'
import { metaDataTypes } from '@/app/services/metaDataClient'
import CardMenu from '@/domain/cards/components/CardMenu.vue'
import { resolveCardState, resolveDefaultCardState } from '@/app/support/cardStates'
import { Card } from '@/types'
import { useCardTitle } from '@/domain/cards/composables/useCardTitle'
import { useExtendDocumentProgress } from '@/domain/cards/composables/useExtendDocumentProgress'
import NodeProcessBadge from '@/app/components/NodeProcessBadge.vue'

const props = withDefaults(
  defineProps<{
    title?: string
    isDashboard?: boolean
  }>(),
  {
    title: undefined,
    isDashboard: false,
  },
)

const cardsStore = useCardsStore()
const { topic } = storeToRefs(cardsStore)
const { parentCard, position, viewId } = useUserLocationStore()

const cardData = computed(() => parentCard.value ?? topic.value)

const expandAsCard = (card: Card) => {
  const { pushNewExpandedRoute: expandCardWithDialog } = useCardExpandedDialogStore()

  expandCardWithDialog(card.id)
}

const { title: cardTitle } = useCardTitle(cardData, (newCardData) => {
  const titleMetaData = resolveMetaDataFromCard(newCardData, metaDataTypes.titleId)
  return getMetaDataValue(titleMetaData)
})

const cardBackgroundColorClass = computed(() => {
  const cardState = resolveCardState(resolveCardStateId(cardData))
  return (cardState ?? resolveDefaultCardState())?.background
})

const showBreadcrumb = computed(() => viewId.value)
const cardId = computed(() => cardData?.value?.id ?? null)

const { init, isGenerating } = useExtendDocumentProgress()

onMounted(() => {
  init(topic.value.id)
})

watch(topic, (newTopic) => {
  init(newTopic.id)
})
</script>
