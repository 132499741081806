import { defineStore } from 'pinia'
import { Card } from '@/types'
import {
  createCard,
  deleteCardById,
  getChildrenData,
  moveCardBetweenColumns,
  openTopicById,
  restoreCardById,
  updateCardOrder,
} from '@/domain/cards/services/cardClient'
import { log } from '@/app/services/errorService'
import { mergeMetaDataWithCardData } from '@/domain/cards/support/helper'
import { v4 as uuid } from 'uuid'
import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { nextTick, toRaw } from 'vue'
import { useUserLocationStore } from '@/app/services/useUserLocationStore'
import { useCardNavigation } from '@/domain/cardExpanded/services/useCardNavigation'
import { ENodeEvents } from '@/app/contracts/ENodeEvents'
import { usePosthog } from '@/app/support/usePosthog'
import { ENodeLevels } from '@/app/contracts/ENodeLevels'

type CardsStoreState = {
  isReadOnly: boolean
  topic: Card | null
  parents: Card[]
  children: {
    [key: string]: Card[]
  }
  creatingCardsDisabled: boolean
  activeCardHistory: Array<string>
  newCreatedCardId: string
  cardError: string
  cardIsNotFound: boolean
}

export const isParent = (topic: Card, parents: Card, cardId: string): boolean => {
  if (topic?.id === cardId ?? false) {
    return true
  }

  return (
    parents.find((card) => card.id === cardId)?.attriubutes?.cardType === 'topic' ?? false
  )
}

export const resolveCardInsertIndex = (
  cards: Card[],
  previousCardId?: string,
): number => {
  const defaultInsertIndex = cards?.length ?? 0
  let insertIndex: undefined | number = undefined

  if (previousCardId) {
    insertIndex = cards?.findIndex((child) => child.id === previousCardId)
    insertIndex = insertIndex === -1 ? defaultInsertIndex : insertIndex + 1
  }

  return insertIndex ?? defaultInsertIndex
}

export const useCardsStore = defineStore('useCardsStore', {
  state: () =>
    ({
      isReadOnly: false,
      topic: {} as Card,
      parents: [],
      children: {},
      creatingCardsDisabled: false,
      cardTitleIsBeingEdited: false,
      activeCardHistory: [],
      newCreatedCardId: '',
      cardError: '',
      cardIsNotFound: false,
    }) as CardsStoreState,
  getters: {
    activeCardId(state) {
      return state.activeCardHistory[state.activeCardHistory.length - 1]
    },
    exposedParents(state) {
      const { viewId } = useUserLocationStore()
      if (viewId.value.length) {
        return state.children[viewId.value] ?? []
      }
      return state.parents
    },
    allCards(state) {
      const values = [state.topic, ...state.parents]
      const children = Object.values(state.children)
      if (!children.length) {
        return values
      }
      Object.values(state.children).map((inner) => values.push(...inner))
      return values
    },
  },
  actions: {
    async fetchChildrenOfAParent(parentCard: any) {
      try {
        const children = await getChildrenData(parentCard)
        if (children) {
          this.children[parentCard.id] = children ?? []
        }
      } catch (e) {
        log(e)
      }
    },
    async findAllParentsAndChildren(topicId: string, forcefulFindAll = false) {
      if (topicId === this.topic.id && !forcefulFindAll) {
        // Do not fetch same topic twice if it's unnecessary
        return
      }
      try {
        const topicData = await openTopicById(topicId)

        this.topic = topicData.data.topic
        this.parents = topicData.data.parents
        this.children = topicData.data.children

        useCardNavigation().init()
      } catch (e: any) {
        this.cardError = e.message
        this.cardIsNotFound = true
        log(e)
      }
    },
    async createParentCard(insertAtIndex: number) {
      try {
        this.newCreatedCardId = uuid()
        const parentCardWithMetaData = await createCard(
          this.topic.id,
          this.newCreatedCardId,
        )

        const nodeCreatedEventPayload = {
          node_id: parentCardWithMetaData?.data?.id ?? null,
          node_level: ENodeLevels.parent,
          node_parent_id:
            parentCardWithMetaData?.data?.relationships?.parent?.data?.id ?? null,
          guide_id: parentCardWithMetaData?.data?.relationships?.topic?.data?.id ?? null,
        }

        usePosthog().capture(ENodeEvents.created, nodeCreatedEventPayload)

        const parentCardData = mergeMetaDataWithCardData(
          parentCardWithMetaData.included,
          [parentCardWithMetaData.data],
        ).data[0]
        const lengthOfTopicChildrenBeforeMerge =
          this.topic.relationships.children.data.length

        this.topic.relationships.children.data.splice(insertAtIndex, 0, {
          id: parentCardData.id,
          type: 'cards',
        })
        this.parents.splice(insertAtIndex, 0, parentCardData)
        if (insertAtIndex !== lengthOfTopicChildrenBeforeMerge) {
          await updateCardOrder(
            this.topic.id,
            'topic',
            this.topic.relationships.children.data,
          )
        }

        useCardNavigation().addIdsOfChildrenIfNotExist(this.topic)
      } catch (e) {
        log(e)
      }
    },
    async updateOrder(parentCard: Card, cards: Card[]) {
      const isParentOrder = parentCard.attributes.cardType === 'topic'
      if (isParentOrder) {
        await this.updateParentsOrder(cards)
        return
      }

      await this.updateChildrenOrder(parentCard.id, cards)
    },
    async createAnEmptyNewCardForContext(
      parentCardId: string,
      previousSiblingId?: string,
    ) {
      const siblingsAreParents = isParent(this.topic, this.parents, parentCardId)
      const siblings = siblingsAreParents ? this.parents : this.children[parentCardId]
      const cardInsertIndex = resolveCardInsertIndex(siblings, previousSiblingId)

      if (siblingsAreParents) {
        await this.createParentCard(cardInsertIndex)
        return
      }
      await this.createChildCard(parentCardId, cardInsertIndex)
    },
    async createChildCard(parentCardId: string, insertAtIndex: number) {
      try {
        // @warn edge case create child in expanded topic not working
        this.newCreatedCardId = uuid()
        const childCardWithMetaData = await createCard(
          parentCardId,
          this.newCreatedCardId,
        )

        const nodeCreatedEventPayload = {
          node_id: childCardWithMetaData?.data?.id ?? null,
          node_level: ENodeLevels.child,
          guide_id: childCardWithMetaData?.data?.relationships?.topic?.data?.id ?? null,
          node_parent_id:
            childCardWithMetaData?.data?.relationships?.parent?.data?.id ?? null,
        }

        usePosthog().capture(ENodeEvents.created, nodeCreatedEventPayload)

        const childCardData = mergeMetaDataWithCardData(childCardWithMetaData.included, [
          childCardWithMetaData.data,
        ]).data[0]

        const parent = this.getCardParent(parentCardId) ?? this.topic
        const lengthOfParentChildrenBeforeMerge =
          parent?.relationships?.children?.data?.length ?? 0

        parent.relationships.children.data.splice(insertAtIndex, 0, {
          id: childCardData.id,
          type: 'cards',
        })
        if (!this.children[parentCardId]) {
          this.children[parentCardId] = []
        }
        this.children[parentCardId].splice(insertAtIndex, 0, childCardData)

        if (insertAtIndex !== lengthOfParentChildrenBeforeMerge) {
          await updateCardOrder(
            this.topic.id,
            'card',
            this.topic.relationships.children.data,
          )
        }

        useCardNavigation().addIdsOfChildrenIfNotExist(parent)
      } catch (e) {
        log(e)
      }
    },
    async updateParentsOrder(parents: Card[]) {
      try {
        this.parents = [...parents]

        const updatedParentOrder = this.parents.map((parent) => ({
          id: parent.id,
          type: 'cards',
        }))

        this.topic.relationships.children.data = [...updatedParentOrder]

        await updateCardOrder(
          this.topic.id,
          'topic',
          this.topic.relationships.children.data,
        )
      } catch (e) {
        log(e)
      }
    },
    async updateChildrenOrder(parentCardId: string, children: Card[]) {
      try {
        this.children[parentCardId] = [...children]

        const updatedChildOrder = children.map((child) => ({
          id: child.id,
          type: 'cards',
        }))

        const parent = this.getCardParent(parentCardId)
        parent.relationships.children.data = [...updatedChildOrder]

        await updateCardOrder(parentCardId, 'card', parent.relationships.children.data)
      } catch (e) {
        log(e)
      }
    },
    async deleteParentCard(cardId: string) {
      const parentIndex = this.parents.findIndex((parent) => parent.id === cardId)
      this.parents.splice(parentIndex, 1)
      const indexOfParentInTopicChildren = (
        this.topic?.relationships?.children?.data ?? []
      ).findIndex((parentAsTopicChild) => parentAsTopicChild.id === cardId)
      if (indexOfParentInTopicChildren >= 0) {
        this.topic.relationships.children.data.splice(indexOfParentInTopicChildren, 1)
      }

      try {
        await deleteCardById(cardId)

        useCardNavigation().deleteIdFromArray(cardId)
      } catch (e) {
        log(e)
      }
    },
    async deleteChildrenCard(parentCardId: string, card: Card) {
      const childIndex = this.children[parentCardId].findIndex(
        (child) => child.id === card.id,
      )
      this.children[parentCardId].splice(childIndex, 1)
      const parent = this.findCardById(parentCardId)
      parent.relationships.children.data = parent.relationships.children.data.filter(
        (child) => child.id !== card.id,
      )

      try {
        await deleteCardById(card.id)

        useCardNavigation().deleteIdFromArray(card.id)
      } catch (e) {
        log(e)
      }
    },
    restoreChildCard() {
      const { lastDeleted } = useRestoreHistory()
      const parentId = lastDeleted.value.value.relationships.parent.data.id
      const parent = this.getCardParent(parentId)
      const oldCardOrder = parent.relationships.children.data
      const restoredCardIndex = oldCardOrder.findIndex(
        (data) => data.id === lastDeleted.value.id,
      )
      this.children[parentId].splice(restoredCardIndex, 0, toRaw(lastDeleted.value.value))

      useCardNavigation().addIdsOfChildrenIfNotExist(parent)
    },
    restoreParentCard() {
      const { lastDeleted } = useRestoreHistory()
      const oldCardOrder = this.topic.relationships.children.data
      const restoredCardIndex = oldCardOrder.findIndex(
        (data) => data.id === lastDeleted.value.id,
      )
      this.parents.splice(restoredCardIndex, 0, toRaw(lastDeleted.value.value))

      useCardNavigation().addIdsOfChildrenIfNotExist(this.topic)
    },
    async restoreLastCard() {
      const { lastDeleted } = useRestoreHistory()
      const { id, type } = lastDeleted.value
      await restoreCardById(id)
      if (type === 'child') {
        this.restoreChildCard()
      } else if (type === 'parent') {
        this.restoreParentCard()
      }
    },
    addBlockToCard(cardId: string, blockId: string) {
      const card = this.findCardById(cardId)
      card.relationships.blocks.data.push({
        id: blockId,
        type: 'blocks',
      })
    },
    pushCardToActiveCardHistory(cardId) {
      if (this.activeCardHistory[this.activeCardHistory.length - 1] !== cardId) {
        this.activeCardHistory.push(cardId)
      }
    },
    popCardFromActiveCardHistory() {
      this.activeCardHistory.pop()
    },
    async fetchChildrenOf(card: any) {
      if (this.children[card.id]) return
      this.children[card.id] = (await getChildrenData(card)) ?? []
    },
    async fetchChildrenOfCurrentContext() {
      Promise.all(this.exposedParents.map((data) => this.fetchChildrenOf(data)))
    },
    getCardParent(parentCardId: string) {
      return this.findCardById(parentCardId)
    },
    findCardById(cardId: string) {
      return this.allCards.find((child) => child.id === cardId)
    },
    findCardEnumeration(cardId: string) {
      if (cardId === this.topic.id) {
        return ''
      }
      const card = this.findCardById(cardId)
      let parentCard = this.findCardById(card?.relationships?.parent?.data?.id)
      const enumeration: any[] = []
      while (cardId !== this.topic.id) {
        enumeration.unshift(
          parentCard?.relationships?.children?.data.findIndex(
            (child) => child.id === cardId,
          ) + 1,
        )
        cardId = parentCard?.id
        parentCard = this.findCardById(parentCard?.relationships?.parent?.data?.id)
      }
      return enumeration.join('.')
    },
    async fetchUntilContextId(id: string) {
      const findAllCardsBelongsToTopic = async () => {
        const childrenOnly = this.allCards.filter(
          (card) =>
            card.attributes.cardType !== 'topic' &&
            card.relationships?.parent?.data?.id !== this.topic.id &&
            !(card.id in this.children),
        )
        await Promise.all(childrenOnly.map((data) => this.fetchChildrenOf(data)))
      }
      await findAllCardsBelongsToTopic()

      while (!this.findCardById(id)) {
        await findAllCardsBelongsToTopic()
      }
    },

    async moveCardToTheNextColumn(card: Card) {
      await this.moveCardBetweenColumns(card, 'right')
    },
    async moveCardToThePreviousColumn(card: Card) {
      await this.moveCardBetweenColumns(card, 'left')
    },
    async moveCardBetweenColumns(card: Card, direction: 'left' | 'right') {
      const cardId = card.id
      const parentCardId = card?.relationships?.parent?.data.id
      const parent = this.findCardById(parentCardId)
      const parentOfParent = this.findCardById(parent?.relationships?.parent?.data.id)
      const filteredArrayOfParentIds: string[] =
        parentOfParent?.relationships?.children?.data.map((data) => data.id)
      const indexOfParentCardId = filteredArrayOfParentIds.indexOf(parentCardId)
      const resultingParentIndex =
        direction === 'right'
          ? (indexOfParentCardId + 1) % filteredArrayOfParentIds.length
          : indexOfParentCardId - 1
      const targetParentId = filteredArrayOfParentIds.at(resultingParentIndex)
      if (!targetParentId) {
        return
      }
      const resultingColumnParentCard = this.findCardById(targetParentId)

      // Remove card from the current parent
      parent.relationships.children.data = parent.relationships.children.data.filter(
        (child) => child.id !== cardId,
      )
      const childIndex = this.children[parentCardId].findIndex(
        (child) => child.id === cardId,
      )
      const cardForReference = this.children[parentCardId][childIndex]
      this.children[parentCardId].splice(childIndex, 1)

      // Add card to the resulting column parent
      cardForReference.relationships.parent.data.id = targetParentId
      resultingColumnParentCard.relationships.children.data.push({
        type: 'card',
        id: cardId,
      })

      if (!this.children[targetParentId]) {
        this.children[targetParentId] = []
      }
      this.children[targetParentId].push(cardForReference)

      useCardNavigation().deleteIdFromArray(cardId)
      useCardNavigation().addIdsOfChildrenIfNotExist(resultingColumnParentCard)

      // Scroll to the added card
      await nextTick()
      const resultingColumnScrollContainer = document.querySelector(
        `[data-card-id="${targetParentId}"]+div.custom-scrollbar`,
      )
      resultingColumnScrollContainer?.scrollIntoView({
        block: 'end',
      })
      resultingColumnScrollContainer?.scroll({
        top: resultingColumnScrollContainer?.scrollHeight,
      })

      await moveCardBetweenColumns(cardId, targetParentId)
    },
  },
})
