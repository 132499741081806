<template>
  <CardMenuBaseAction
    v-if="isVisible"
    id="edit-title"
    :aria-label="actionTitle"
    :is-disabled="isDisabled || isGenerating"
    @click="generateSteps"
  >
    <LightningBoltIcon
      class="h-5 w-5"
      :class="isDisabled || isGenerating ? ['text-base-400'] : []"
    />
    <span :class="isDisabled || isGenerating ? ['text-base-400'] : []">
      {{ actionTitle }}
    </span>
  </CardMenuBaseAction>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenuActionGenerateChildren'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import CardMenuBaseAction from '@/domain/cards/components/CardMenuActionBase.vue'
import { type Card } from '@/types'
import { LightningBoltIcon } from '@heroicons/vue/outline'
import { computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useAiActions } from '@/domain/cards/composables/useAiActions'
import { useExtendDocumentProgress } from '@/domain/cards/composables/useExtendDocumentProgress'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'

const props = withDefaults(defineProps<{ cardData: Card }>(), {
  cardData: {} as Card,
})

const actionTitle = 'Generate steps'

const { currentRoute } = useRouter()
const isVisible = computed(() => currentRoute.value.name !== CRouteNames.dashboard)
const isDisabled = computed(
  () => (props.cardData.relationships?.children?.data?.length ?? 0) !== 0,
)

const { execute: generateStepsAction, actionTypes } = useAiActions()

const {
  init,
  documentId,
  isGenerating,
  start: markExtendingDocumentStarted,
  done: markExtendingDocumentAsDone,
} = useExtendDocumentProgress()

const generateSteps = async () => {
  const isDocument = props.cardData.attributes.cardType === 'topic'
  let actionType = actionTypes.detailANodeWithAdditionalSteps

  let whenDoneAction: () => void = () => undefined

  if (isDocument && documentId.value) {
    markExtendingDocumentStarted()
    actionType = actionTypes.extendDocumentWithAiId
    whenDoneAction = () => {
      useCardsStore().findAllParentsAndChildren(documentId.value as string, true)
      markExtendingDocumentAsDone()
    }
  }

  await generateStepsAction(props.cardData, actionType, whenDoneAction)
}

onMounted(() => {
  if (props.cardData?.attributes?.cardType === 'topic') {
    init(props.cardData.id)
  }
})

watch(
  () => props.cardData,
  (newDocument) => {
    if (props.cardData?.attributes?.cardType === 'topic') {
      init(newDocument.id)
    }
  },
)
</script>
