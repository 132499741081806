<template>
  <Menu as="div" @click.stop.prevent>
    <MenuButton
      id="toggle-menu"
      ref="reference"
      v-slot="{ open }"
      aria-label="Toggle card dropdown"
      class="flex items-center rounded-sm p-1 focus:outline-none focus:ring-0"
    >
      <span class="sr-only">Open options</span>
      <DotsVerticalIcon
        class="h-5 w-5"
        :class="[usedInTitleContext && 'text-base-500']"
        aria-hidden="true"
      />

      <Teleport to="body">
        <Transition
          enter-from-class="opacity-0"
          enter-to-class="opacity-100 ease-out duration-200"
          leave-active-class="ease-in duration-75"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
          @before-enter="onBeforeEnter"
          @enter="onEnter"
          @leave="onLeave"
        >
          <MenuItems v-if="open" as="template">
            <div
              class="fixed top-0 left-0 z-50 w-[240px] overflow-hidden rounded-md bg-white drop-shadow-xl focus:outline-none border border-base-300"
              :style="menuItemsStyles"
            >
              <CardMenuTitleEditButton v-if="!usedInTitleContext && !isProcessing" />
              <CardMenuActionGenerateChildren
                v-if="!isProcessing"
                :card-data="props.cardData"
              />
              <CardMenuActionExplain
                v-if="!usedInTitleContext && !isProcessing && props.isExpanded"
                :card-data="props.cardData"
              />
              <hr v-if="!isProcessing" />
              <CardMenuActionMoveCard :card-data="props.cardData" :type="type" />
              <hr v-if="!usedInTitleContext" />
              <CardMenuActionApplyState :card-data="props.cardData" />
              <hr />
              <CardMenuActionCreateCollaborationUrl :card-data="props.cardData" />
              <CardMenuActionCreateCopyUrl
                :card-data="props.cardData"
                :as-one-time-copy="false"
              />
              <CardMenuActionCreateCopyUrl
                :card-data="props.cardData"
                :as-one-time-copy="true"
              />
              <hr />
              <CardMenuActionDelete ref="floating" :card-data="props.cardData" />
            </div>
          </MenuItems>
        </Transition>
        <div v-if="open" class="fixed top-0 left-0 h-full w-full z-30"></div>
      </Teleport>
    </MenuButton>
  </Menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CardMenu'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CardMenuActionApplyState from '@/domain/cards/components/CardMenuActionApplyState.vue'
import CardMenuActionCreateCollaborationUrl from '@/domain/cards/components/CardMenuActionCreateCollaborationUrl.vue'
import CardMenuActionCreateCopyUrl from '@/domain/cards/components/CardMenuActionCreateCopyUrl.vue'
import CardMenuActionMoveCard from '@/domain/cards/components/CardMenuActionMoveCard.vue'
import CardMenuActionDelete from '@/domain/cards/components/CardMenuActionDelete.vue'
import CardMenuTitleEditButton from '@/domain/cards/components/CardMenuTitleEditButton.vue'
import { DotsVerticalIcon } from '@heroicons/vue/outline'
import { Card } from '@/types'
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { useElementBounding } from '@vueuse/core'
import { CardTypeProps } from '../support/interfaces'
import CardMenuActionGenerateChildren from '@/domain/cards/components/CardMenuActionGenerateChildren.vue'
import CardMenuActionExplain from '@/domain/cards/components/CardMenuActionExplain.vue'

interface CardMenuProps {
  cardData: Card
  type: CardTypeProps
  usedInTitleContext: boolean
  isProcessing?: boolean
  isExpanded?: boolean
}

const props = withDefaults(defineProps<CardMenuProps>(), {
  cardData: {} as Card,
  usedInTitleContext: false,
  isProcessing: false,
  isExpanded: false,
})

const emit = defineEmits<{
  (event: 'menuVisibilityChanged', payload: boolean): void
}>()

const reference = ref<HTMLElement | null>(null)
const floating = ref<HTMLElement | null>(null)

const { bottom, left, update } = useElementBounding(reference)

const customHeight = ref(0)
const menuItemWidth = ref(0)

const positionX = computed(() => left.value - (menuItemWidth.value - 20))
const positionY = computed(() => {
  let result = bottom.value + 2 - customHeight.value
  result = result < 0 ? bottom.value + 2 : result
  return result
})

const menuItemsStyles = computed(() => ({
  transform: `translate3d(${positionX.value}px, ${positionY.value}px, 0px)`,
}))

const onBeforeEnter = (el: any) => {
  update()
  emit('menuVisibilityChanged', true)
}

const onEnter = (el: any, done: () => void) => {
  const { bottom, height, width } = (el as HTMLElement).getBoundingClientRect()
  if (innerHeight - bottom < 20) {
    customHeight.value = height + 30
  }
  menuItemWidth.value = width
  done()
}

const onLeave = (el) => {
  emit('menuVisibilityChanged', false)
}
</script>
