import { type Card } from '@/types'
import { metaDataTypes, updateMetaData } from '@/app/services/metaDataClient'

const doExecute = async (node: Card, type: metaDataTypes, whenDone?: () => void) => {
  const metadata = node.relationships.metadata.data.find((metaDataItem) => {
    if (metaDataItem.relationships.metadataType.data.id === type) {
      return metaDataItem
    }
  })

  metadata.attributes.payload = Object.assign(metadata.attributes.payload, {
    status: 'new',
  })

  await updateMetaData(metadata.id, metadata.attributes.payload, false)

  if (whenDone) {
    // @info nasty workaround because we aren't able to get the real finalizing time
    setTimeout(whenDone, 5000)
  }
}

export const useAiActions = () => {
  return {
    execute: doExecute,
    actionTypes: metaDataTypes,
  }
}
