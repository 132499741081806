import { log } from './errorService'
import { getMyUser, updateMyUserName } from '@/app/services/usersClient'
import { createGlobalState, useStorage } from '@vueuse/core'
import { computed, readonly } from 'vue'
import { defineGlobalStore } from '@/app/support/defineGlobalStore'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'

export const useUserStore = defineGlobalStore(
  'userStore',
  createGlobalState(() => {
    const userName = useStorage<string | null>('uname', null)
    const email = useStorage<string | null>('umail', null)
    const userId = useStorage<string | null>('uid', null)

    const isLoggedIn = computed(() => {
      return (
        !!useAuthentication().jwt.value &&
        Boolean(userName.value) &&
        userName.value !== 'undefined'
      )
    })
    const userNameInitials = computed(() => {
      if (userName.value) {
        const parts = userName.value.split(' ')
        const initials = [] as string[]

        parts.forEach((item) => {
          if (item.length > 0 && item !== '') {
            initials.push(item[0])
          }
        })

        const resultingInitials = [] as string[]

        resultingInitials.push(initials[0])
        if (initials.length > 1) {
          resultingInitials.push(initials[initials.length - 1])
        }

        return resultingInitials.join('').toUpperCase()
      }

      return 'JD'
    })

    const _restUserData = () => {
      const userProperties = {
        user_id: userId.value ?? '',
        user_email: email.value ?? '',
        user_name: userName.value ?? '',
      }

      userName.value = ''
      email.value = ''
      userId.value = ''

      captureWithUser(EUserEvents.resetUserData, {
        ...userProperties,
      })

      return userProperties
    }

    const refresh = async (): Promise<void> => {
      if (!userName.value || !userId.value || !email.value) {
        try {
          const userData = await getMyUser()
          const user = userData?.data?.data

          userName.value = user?.attributes?.name
          email.value = user?.attributes?.email
          userId.value = user?.id
        } catch (error) {
          log(error)
        }
      }
    }

    const updateUserName = async (name: string) => {
      try {
        await updateMyUserName(name)
        userName.value = name
      } catch (error) {
        log(error)
      }
    }

    return {
      userName: readonly(userName),
      email: readonly(email),
      userId: readonly(userId),
      isLoggedIn,
      userNameInitials,
      reset: _restUserData,
      updateUserName,
      refresh,
    }
  }),
)
