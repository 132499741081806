<template>
  <div class="relative">
    <span
      class="relative z-0 flex h-8 w-8 items-center justify-center rounded-full bg-base-100 border border-state-error text-sm font-semibold text-state-error hover:text-state-error-focus hover:border-state-error-focus"
      :class="[$options.name]"
      aria-label="User avatar"
    >
      {{ userNameInitials }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserStore } from '@/app/services/useUserStore'

export const componentName = 'UserAvatar'

export default defineComponent({
  name: componentName,
  setup() {
    const userStore = useUserStore()
    const { userNameInitials } = userStore

    return {
      userNameInitials,
    }
  },
})
</script>

<style scoped></style>
