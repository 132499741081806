import { useConfig } from '@/app/services/useConfig'

export enum SupportedEnvironments {
  local = 'local',
  mr = 'mr',
  staging = 'staging',
  production = 'production',
}

export class Environment {
  static isLocal(): boolean {
    return Environment.getName() === SupportedEnvironments.local
  }

  static isMr(): boolean {
    return Environment.getName() === SupportedEnvironments.mr
  }

  static isStaging(): boolean {
    return Environment.getName() === SupportedEnvironments.staging
  }

  static isProduction(): boolean {
    return Environment.getName() === SupportedEnvironments.production
  }

  static getName(): string {
    const environment = useConfig().get().environment
    if (!(environment in SupportedEnvironments)) {
      throw new Error(`'${environment}' is not supported`)
    }
    return environment
  }
}
