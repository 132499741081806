<template>
  <Transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div
      v-if="isDialogVisible"
      class="z-50 absolute flex flex-col h-screen w-screen justify-center DialogInset"
      :class="[componentName, $attrs.class]"
      tabindex="0"
      @click.stop="closeDialog"
    >
      <div
        class="flex justify-between items-center w-full border-b border-b-base-100 max-h-nav min-h-nav px-4 LogoContainer"
      >
        <slot name="logo" />

        <div class="group flex">
          <slot name="backlink" :closeDialog="closeDialog" />
          <button v-if="!disableCloseButton" @click="isDialogVisible = false">
            <XIcon class="h-8 w-8 text-base-400 group-hover:text-base-content" />
          </button>
        </div>
      </div>

      <slot name="dialogContent">
        <div
          class="flex h-full w-full items-center justify-center rounded-lg bg-base-content"
        >
          Default Dialog content
        </div>
      </slot>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { XIcon } from '@heroicons/vue/outline'

export const componentName = 'BaseFullscreenDialog'

export default defineComponent({
  name: componentName,
  components: {
    XIcon,
  },
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    dialogIsVisible: boolean
    disableCloseButton: boolean
  }>(),
  {
    dialogIsVisible: false,
    disableCloseButton: false,
  },
)

const emit = defineEmits<{
  (event: 'dialogVisibilityChanged', payload: boolean): void
}>()

const isDialogVisible = computed({
  get() {
    return props.dialogIsVisible
  },
  set(value: boolean) {
    emit('dialogVisibilityChanged', value)
  },
})

const closeDialog = () => {
  isDialogVisible.value = false
}
</script>
