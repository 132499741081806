<template>
  <BaseAppLayout
    class="!w-auto"
    :class="componentName"
    :can-scroll="true"
    :aria-label="componentName"
  >
    <template #navigation>
      <Navigation />
      <CardListingHeader
        class="bg-base-100 !mx-0 px-4 w-full py-2 shadow-lg"
        title="My recent guides"
        :is-dashboard="true"
      >
        <template #header-actions>
          <TopicSortMenu class="ml-auto" />
        </template>
      </CardListingHeader>
    </template>
    <template #content>
      <CardListingTopic />
    </template>
    <template #dialog>
      <CreateNewTopicDialog />
    </template>
  </BaseAppLayout>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import BaseAppLayout from '@/app/components/BaseAppLayout.vue'
import Navigation from '@/app/components/Navigation.vue'
import CardListingHeader from '@/domain/cards/components/CardListingHeader.vue'
import TopicSortMenu from '@/app/components/TopicSortMenu.vue'
import CreateNewTopicDialog from '@/domain/createTopic/components/CreateNewTopicDialog.vue'
import CardListingTopic from '@/domain/cards/components/CardListingTopic.vue'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { useUserLocationStore } from '@/app/services/useUserLocationStore'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { useBlockTypeStore } from '@/domain/blockList/services/useBlockTypeStore'
import { useCreateNewTopicDialogStore } from '@/domain/createTopic/services/useCreateNewTopicDialogStore'

export const componentName = 'PDashboard'

export default defineComponent({
  name: componentName,
  components: {
    BaseAppLayout,
    Navigation,
    CardListingTopic,
    CreateNewTopicDialog,
    CardListingHeader,
    TopicSortMenu,
  },
  setup() {
    const dialog = useCreateNewTopicDialogStore()

    onMounted(() => {
      useBlockTypeStore().ensureBlockTypesAreFetched()
      useUserLocationStore().reset()
      useCardsStore().$reset()
      useTopicsStore().findAllTopics()
    })

    return {
      componentName,
      dialog,
    }
  },
})
</script>

<style scoped></style>
